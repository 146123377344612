import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AggregateFunctionOptions, AssignChartDataOptions } from '../../../echart-base';
import { EchartVariationBase } from '../../../echart-variation-base';
import { groupData } from '../../../helpers/general-functions';
import { TreeMapChart } from '../../base/tree-map-chart';

export class GroupsTreeMapChart extends TreeMapChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'GROUPS';
  //   chartOptions: EChartsOption = {
  //     xAxis: {
  //       type: 'category',
  //       data: [],
  //     },
  //     yAxis: {
  //       type: 'value',
  //     },
  //     series: [
  //       {
  //         data: [],
  //         type: 'line',
  //         smooth: true,
  //       },
  //     ],
  //   };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = null; //options?.name;
    optionsCopy.series[0].data = options?.data;
    //@TODO: children format original data
    return optionsCopy;
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    return {
      data: groupData(
        options?.response?.aggregation_value,
        options?.payload?.groupByFields,
        options.drillDownAggregationField,
        this.translationModulePrefix
      ),
      chartTitle: null,
    };
  }
}
