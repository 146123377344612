<p-sidebar [(visible)]="display" (onHide)="onHide()" position="right" styleClass="p-sidebar-md">
  <ng-template pTemplate="header">
    <div class="flex justify-content-between my-5 w-full">
      <h4 class="mb-0 text-center w-full">Translations</h4>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <app-languages-trail [itemCode]="itemCode" [autoFocus]="autoFocus"></app-languages-trail>
  </ng-template>
</p-sidebar>
