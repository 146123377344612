import { translateFromLocaleStorage } from '@shared/classes/helpers';
import { AggregationField, FilterItem, GroupingField } from '@shared/classes/model';
import { lowerCase, startCase } from 'lodash-es';
import { AggregationValue } from '../echart-base';

export function isString(str) {
  return typeof str === 'string';
}
export function isNullObj(obj) {
  return obj == null || obj == undefined || obj == '' || obj.length == 0;
}
export function humanizeCasedString(value: string): string {
  let retValue = '';
  if (value === '' || !value) {
    return retValue;
  }
  if (isValidCode(value)) return value;
  retValue = startCase(lowerCase(value));
  // Split the input string into words
  const words = retValue?.split(/\s+/);
  // Filter out consecutive duplicate words
  const filteredWords = words?.filter((word, index) => {
    return index === 0 || word !== words?.[index - 1];
  });
  // Join the filtered words back into a string
  retValue = filteredWords?.join(' ');
  return retValue;
}
export function isValidCode(code: string): boolean {
  if (isNullObj(code) || typeof code !== 'string') return false;

  let codeStart = code?.substring(0, 3);
  if (isNullObj(codeStart) || isNaN(Number.parseInt(code?.substring(3)))) {
    return false;
  }
  return true;
}
export interface Group {
  key?: string | number | null;
  name?: string | number | null;
  children?: Group[];
  rows?: AggregationValue[];
  rowspan?: number;
  value?: number;
  field?: string;
  fieldData?: GroupingField;
  [x: string]: any;
}
export function groupData(
  data: AggregationValue[],
  fields: GroupingField[],
  drillDownAggregationField: string,
  translationKeyPrefix: string
): Group[] {
  const grouped: Group[] = [];

  data.forEach((item) => {
    let currentLevel = grouped;

    fields.forEach((field) => {
      const key = item?.group_id?.[getGroupFieldIdKey(field)] || 'EMPTY_VALUE';
      let group = currentLevel.find((g) => g.key === key);

      if (!group) {
        group = {
          key,
          name: translateFromLocaleStorage(`${translationKeyPrefix}.fields.${field?.fieldName}.${key}`, key),
          children: [],
          rows: [],
          rowspan: 0,
          value: 0,
          field: getGroupFieldIdKey(field),
          fieldData: field,
        };
        currentLevel.push(group);
      }
      //increase rowspan per item match
      group.rowspan++;
      group.value += item?.[drillDownAggregationField] || 0;
      //proceed into inner level in order to find the final assign location for the item
      currentLevel = group.children;
    });

    // Add the item to the last level
    currentLevel.push(item);
  });

  return grouped;
}
export function getGroupFieldIdKey(field: GroupingField) {
  if (!field) return '';
  if (
    field.typeShape == GroupingField.TypeShapeEnum.Normal ||
    field.typeShape == GroupingField.TypeShapeEnum.Datetime
  ) {
    return field?.fieldName;
  } else {
    return field?.fieldName + '_' + field?.dateTimePart?.toLowerCase();
  }
}
export function getAggregationFieldIdKey(field: AggregationField) {
  if (!field) return '';
  return field?.fieldName + '_' + field?.operation?.toLowerCase();
}
export function getAggregationFieldIdKeyList(fields: AggregationField[]) {
  return fields.map((x) => getAggregationFieldIdKey(x));
}
export function getDrillDownFilterField(
  field: GroupingField,
  value: string,
  nullCompareField = 'EMPTY_VALUE'
): FilterItem {
  return value === nullCompareField
    ? ({ property: field?.fieldName, operation: 'IS_NULL', typeShape: field.typeShape } as FilterItem)
    : getFilterField(field, value);
}
export function getFilterField(field: GroupingField, value: string, operation: FilterItem.OperationEnum = 'EQUAL') {
  return {
    property: field?.fieldName,
    operation: operation,
    value: field?.dateTimePart ? parseInt(value) : value,
    typeShape: field?.typeShape,
    dateTimePart: field?.dateTimePart,
  } as FilterItem;
}
