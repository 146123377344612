import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Base, PermissionActions } from '@shared/classes';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { PathResolverService } from '@shared/services/path-resolver.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { AppBreadcrumbService } from 'app/app.breadcrumb.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-tree-mode-switch',
  templateUrl: './tree-mode-switch.component.html',
  styleUrls: ['./tree-mode-switch.component.scss'],
})
export class TreeModeSwitchComponent extends Base implements OnInit {
  @Input() set moduleKeyword(moduleKeyword: string) {
    this._moduleKeyword = moduleKeyword;
    this.checkViewOptionsPermissions();
  }
  private _moduleKeyword: string;
  get moduleKeyword() {
    return this._moduleKeyword;
  }
  // @Output() onViewModeChange: EventEmitter<IViewMode> = new EventEmitter();

  pageViewMode: string = 'list';
  treeIcon: string = 'pi pi-sitemap';
  treeListIcon: string = 'fa-folder-tree fas';

  treeOption = { label: '', value: 'tree', icon: 'pi pi-sitemap', tooltip: 'Tree' };
  viewOption = { label: '', value: 'list', icon: 'pi pi-list', tooltip: 'List' };
  viewModeOptions = [
    { label: '', value: 'list', icon: 'pi pi-list', tooltip: 'List' },
    { label: '', value: 'tree', icon: 'pi pi-sitemap', tooltip: 'Tree' },
  ];
  viewModeTreeListOptions = [
    { label: '', value: 'list', icon: 'pi pi-list', tooltip: 'List' },
    { label: '', value: 'tree', icon: 'fa-folder-tree fas', tooltip: 'Tree' },
  ];
  viewModeBoardListOptions = [
    { label: '', value: 'list', icon: 'pi pi-list', tooltip: 'List' },
    { label: '', value: 'tree', icon: 'fas fa-table-columns', tooltip: 'Board' },
  ];
  viewModeDashboardOptions = [
    { label: '', value: 'tabs', icon: 'fas fa-table-columns', tooltip: 'Tabs' },
    { label: '', value: 'cards', icon: 'pi pi-th-large', tooltip: 'Board' },
  ];
  constructor(
    // private breadcrumbService: AppBreadcrumbService,
    public viewModeService: ViewModeService,
    // private router: Router,
    private perms: NgxPermissionsService,
    private appDialogService: AppDialogService,
    private pathResolverService: PathResolverService,
    public breadCrumbService: AppBreadcrumbService,
    private router: Router,
  ) {
    super();
    this.viewModeService.viewModeState.pipe(takeUntil(this.destroy$)).subscribe((viewMode) => {
      this.pageViewMode =
        viewMode == 'edit'
          ? this.breadCrumbService.treeIcon == 'pi pi-th-large'
            ? 'cards'
            : 'tree'
          : this.breadCrumbService.treeIcon == 'pi pi-th-large'
            ? 'tabs'
            : 'list';
    });
  }

  ngOnInit(): void {
    this.checkViewOptionsPermissions();
  }
  onChangeViewMode(mode: { event?: any; value?: string }) {
    this.pathResolverService.changeTreeViewByPath(
      mode.value,
      this.treeSwitchOptions,
      this.breadCrumbService.treeIcon == 'pi pi-th-large' || mode.value == 'list'
    );

  }
  checkViewOptionsPermissions() {
    //@TODO: fix shared moduleKeyword placement so that you can tell which module we are in from anywhere in code.
    if (this.moduleKeyword) {
      let options = [];
      this.perms.hasPermission(PermissionActions.Read + this.moduleKeyword).then((res) => {
        options = [];
        if (res) options.push({ ...this.treeOption, icon: this.breadCrumbService.treeIcon || 'fa-folder-tree fas' });
        if (res) options.push(this.viewOption);
        this.viewModeOptions = [...options];
      });
      this.viewModeOptions = [...options];
    } else {
      // let options = [];
      // options.push({...this.treeOption,icon:this.breadCrumbService.treeIcon || 'fa-folder-tree fas'});
      // options.push(this.viewOption);
      // this.viewModeOptions = [...options];
    }
  }
  get treeSwitchOptions() {
    return this.breadCrumbService.treeIcon == 'pi pi-sitemap'
      ? this.viewModeOptions
      : this.breadCrumbService.treeIcon == 'fas fa-table-columns'
        ? this.viewModeBoardListOptions
        : this.breadCrumbService.treeIcon == 'pi pi-th-large'
          ? this.viewModeDashboardOptions
          : this.viewModeTreeListOptions;
  }
}
