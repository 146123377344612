import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  BaseViewItem,
  ModuleKeywordRootPath,
  getModuleKeywordByCode,
  isNullObj,
  makePlural,
  routeToLocaleCase,
  toCamelCase
} from '@shared/classes';
import { CodeLabelCacheService, PathResolverService } from '@shared/services';
import { isArray } from 'lodash-es';
import { MenuItem } from 'primeng/api';
import { DropdownFilterOptions } from 'primeng/dropdown';
@Component({
  selector: 'app-target-code-list-box-view',
  templateUrl: './target-code-list-box-view.component.html',
  styleUrl: './target-code-list-box-view.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class TargetCodeListBoxViewComponent extends BaseViewItem implements OnInit {
  filterValue: string | undefined = '';

  _value;
  @Input() set value(value) {
    this._value = value;
    this.fetchCodeLabels();
  }
  get value() {
    return this._value;
  }
  _currentItem: MenuItem;
  set currentItem(value: MenuItem) {
    this._currentItem = value ?? [];
    this.listOfData = [...value?.list];
    this.filteredData = [...value?.list];
    this.customFilterFunction(null);
  }
  get currentItem() {
    return this._currentItem;
  }
  @Input() filterFields: string[] = ['code', 'label'];
  @Input() preventSelection = true;
  @Input() heigth = '30vh';
  @Input() dirty: boolean = false;
  @Input() invalid: boolean = false;
  @Input() disabled: boolean = false;
  @Input() buttonEnabled: boolean = false;
  @Input() addButtonEnabled: boolean = false;
  @Output() onAddButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter();

  menuItems: MenuItem[] = [];
  listOfData = [];
  currentList = [];
  filteredData = [];
  loading = true;

  constructor(
    private pathResolverService: PathResolverService,
    private codeLabelCacheService: CodeLabelCacheService,
    private localeService: LocaleService
  ) {
    super();
  }
  ngOnInit(): void { }

  fetchCodeLabels() {
    this.listOfData = [];

    if (this.value && isArray(this.value)) {
      this.loading = true;
      let listOfObj = {};
      this.value.forEach((x) => {
        this.codeLabelCacheService.addCode(x).subscribe((y) => {
          const code = x?.code ? x.code : x;
          listOfObj[code] = { code: code, label: y?.label, loading: y.loading };
          this.listOfData = [...Object.values(listOfObj).map((z) => z)];
          this.filteredData = [...this.listOfData];
          if (this.listOfData?.length >= this.value?.length) {
            this.initMenuItems();
          }
        });
      });
    } else {
      this.loading = false;
    }
  }

  initMenuItems() {
    this.menuItems = [];
    let moduleMap = {};
    this.listOfData.forEach((x) => {
      try {
        const module = getModuleKeywordByCode(x?.code);
        moduleMap[module] = [...(moduleMap[module] ?? []), x];
      } catch (e) { }
    });
    if (!isNullObj(moduleMap) && Object.keys(moduleMap).length > 1) {
      this.menuItems.push({
        label: this.localeService.translate(`general.actions.All`, 'All'),
        list: this.listOfData,
        command: () => { },
      });

      this.currentItem = this.currentItem ?? {
        label: this.localeService.translate(`general.actions.All`, 'All'),
        list: this.listOfData,
        command: () => { },
      };
    }

    Object.entries(moduleMap).forEach((x) => {
      this.menuItems.push({
        label: this.localeService.translate(`modules.${routeToLocaleCase(ModuleKeywordRootPath[x[0]])}.${toCamelCase(x[0])}.${makePlural(toCamelCase(x[0]))}`),
        list: x[1],
        command: () => { },
      });
    });
    if (this.menuItems.length) {
      this.currentItem = this.currentItem ?? this.menuItems[0];
    }
  }

  preventSelectionFunc(event): void {
    if (this.preventSelection) {
      event.preventDefault(); // Prevent default selection behavior
    }
  }

  customFilterFunction(event: KeyboardEvent) {
    // options?.filter(event);
    if (!this.filterValue || !this.filterFields.length) {
      this.filteredData = [...this.listOfData]; // Return the original list if no query or fields are provided
    }

    const query = this.filterValue.toLowerCase(); // Make the query case-insensitive

    this.filteredData = [
      ...this.listOfData.filter((object) =>
        this.filterFields.some((field) => String(object[field]).toLowerCase().includes(query))
      ),
    ];
  }
  resetFunction(options: DropdownFilterOptions) {
    options?.reset();
    this.filterValue = '';
  }
  buttonClick() {
    this.onButtonClick?.emit(this);
  }
  addButtonClick() {
    this.onAddButtonClick?.emit(this.filterValue);
  }
}
