<div class="grid">
  @for (card of data; track card) {
    <div class="col-12 lg:col-4 p-3">
      <app-custom-card-view
        [showValue]="false"
        [title]="card?.title | translate"
        [color]="card?.color"
        [action]="null"
        [icon]="card?.icon"
      >
        <a [routerLink]="card?.routerLink" [class]="'p-button p-button-' + card?.color + ' flex flex-row gap-2'"
          >{{ card?.buttonTitle | translate
          }}<i [class]="localeService?.language?.direction == 'RTL' ? 'pi pi-angle-left' : 'pi pi-angle-right'"></i>
        </a>
      </app-custom-card-view>
    </div>
  }
</div>
