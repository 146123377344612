<app-dashlet [config]="{ title: title }">
  @if (!drilldownTableVisible) {
    <div class="border border-gray-200 border border border-gray-200">
      <!-- <h4 *ngIf="!showSummaryOnly" class="header-title mb-3">
    Risk Matrix
    <i
      class="pi pi-external-link ml-2 cursor-pointer"
      style="font-size: 1rem"
      (click)="openModal()"
      *ngIf="showExpandButton"
    ></i>
  </h4>
  <p *ngIf="!showSummaryOnly" class="text-muted font-14 pb-2">

    Risk rating = Impact x Probability

  </p> -->

      <div class="w-full">
        <p-table
          [value]="activeMeth?.likelihoodValues"
          styleClass="p-datatable-gridlines"
          [tableStyle]="{ 'min-width': '45rem', 'text-align': 'center', 'margin-top': '2rem' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="text-400 font-medium">
                <p>Total Risks : {{ totalCnt }}</p>
              </th>
              @for (impact of activeMeth?.impactValuesDto; track impact; let i2 = $index) {
                <th class="text-400 font-medium">
                  <p>{{ impact.name }} ({{ impact.value }})</p>
                  <p>Total Risks : {{ impactValues[impact.name] ?? 0 }}</p>
                </th>
              }
            </tr>
          </ng-template>

          <ng-template pTemplate="body" styleClass="p-datatable-tbody border-noround" let-likelihood>
            <tr>
              @for (impact of activeMeth.impactValuesDto; track impact; let j2 = $index) {
                @if (j2 == 0) {
                  <td class="py-2 pl-2 border-noround text-400 font-medium" scope="row">
                    <p>
                      {{ likelihood.name }}
                      ({{ likelihood.value }})
                    </p>
                    <p>Total Risks : {{ likelihoodValues[likelihood.name] ?? 0 }}</p>
                  </td>
                }
                <td
                  class="border-noround text-0 text-center align-items-center justify-content-center"
                  [ngStyle]="{ 'background-color': getCellColor(impact.value * likelihood.value) }"
                >
                  <!-- <p-chip [label]="(impact.value * likelihood.value)?.toFixed(0)"></p-chip> -->
                  <app-circle-badge-item
                    [displayText]="true"
                    [status]="'white'"
                    [customColor]="'#000000'"
                    [text]="
                      riskMap.has(impact.name + likelihood.name) ? riskMap.get(impact.name + likelihood.name) + '' : '0'
                    "
                    [styleClass]="'p-4 m-auto mt-4 mb-1'"
                    class="cursor-pointer"
                    (click)="showDrilldownTable({ residualLikelihood: likelihood.name, residualImpact: impact.name })"
                    [pTooltip]="'Show ' + likelihood.name + ' & ' + impact.name + ' Risks'"
                    tooltipPosition="top"
                  ></app-circle-badge-item>
                  <app-badge-item
                    [displayText]="true"
                    [status]="'white'"
                    [badgeType]="'solid'"
                    [text]="(impact.value * likelihood.value)?.toFixed(0)"
                    [styleClass]="'m-auto'"
                    [ngStyle]="{ float: 'right' }"
                    [customIcon]="'hidden'"
                  ></app-badge-item>
                  <!-- <small style="color: rgb(255, 255, 255);mix-blend-mode: difference;">
            {{impact.value * likelihood.value}}</small> -->
                </td>
              }
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  } @else {
    <div class="layout-breadcrumb-container p-0">
      <p-breadcrumb
        class="max-w-full layout-breadcrumb p-0"
        [model]="drillDownPreviousOptions?.length > 0 ? drillDownPreviousOptions : null"
        [home]="home"
        (onItemClick)="onDrillDownItemClick($event)"
        [styleClass]="'pl-1 pb-1 pt-1'"
      />
    </div>
    <app-widget-table
      class=""
      [title]="tableCardContent?.name"
      [cardContent]="tableCardContent"
      [styleClass]="'relative m-0 '"
      style="height: calc(100% - 25px)"
    >
      <ng-template let-config #headerTemplate> </ng-template>
    </app-widget-table>
  }
</app-dashlet>
