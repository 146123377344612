import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AggregateFunctionOptions, AssignChartDataOptions, EchartBase, EchartFieldDefinition } from '../../echart-base';
import { groupData } from '../../helpers/general-functions';

export class SunburstChart extends EchartBase {
  chartOptions: EChartsOption = {
    legend: null,
    series: [
      {
        type: 'sunburst',
        emphasis: {
          focus: 'ancestor',
        },
        data: [],
        radius: [0, '100%'],
        itemStyle: {
          borderWidth: 3,
          borderColor: 'rgba(0,0,0,0.1)',
        },
        label: {
          rotate: 'radial',
          // overflow: 'truncate',
          // ellipsis: '...',
          fontSize: 9,
          // width: 50,
        },
        labelLayout: function (params) {
          const { align, verticalAlign, labelRect, rect } = params;
          let availableWidth = rect.width;
          let availableHeight = rect.height;

          // Calculate the half-width and half-height
          const halfWidth = availableWidth / 2;
          const halfHeight = availableHeight / 2;

          // Use the Pythagorean theorem to calculate the length of the line
          const lineLength = Math.sqrt(halfWidth * halfWidth + halfHeight * halfHeight);

          // if (align === 'left' || align === 'center' || align === 'right') {
          //   // Horizontally aligned, consider width
          //   availableWidth = (rect.width < labelRect.width ? rect.width : labelRect.width) - 5;
          // }
          // if (verticalAlign === 'top' || verticalAlign === 'middle' || verticalAlign === 'bottom') {
          //   // Vertically aligned, consider height
          //   availableHeight = (rect.height < labelRect.height ? rect.height : labelRect.height) - 8;
          // }
          return {
            // hideOverlap: true,
            width: lineLength,
            // (availableWidth > availableHeight ? availableWidth : availableHeight) > 70
            //   ? availableWidth > availableHeight
            //     ? availableWidth
            //     : availableHeight
            //   : 70,
          };
        },
        levels: [],
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'SUN_BURST';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = null; //options?.name;
    optionsCopy.series[0].data = options?.data;
    const levelsDepth = options?.initOptions?.aggregationResult?.payload?.groupByFields?.length - 1;
    const radiusPerLevel = levelsDepth >= 1 ? 72 / levelsDepth : 70;
    optionsCopy.series[0].levels = [
      {},
      ...options?.initOptions?.aggregationResult?.payload?.groupByFields?.map((x, index) => {
        if (index == 0) {
          return {
            r0: '5%',
            r: radiusPerLevel + '%',
            itemStyle: {
              borderWidth: 2,
            },
            label: {
              formatter: '{b}: {c}',
              overflow: 'truncate',
              ellipsis: '...',
            },
          };
        } else if (index == levelsDepth) {
          return {
            r0: '73%',
            r: '76%',
            label: {
              position: 'outside',
              padding: 3,
              silent: false,
              formatter: '{b}: {c}',
            },
            labelLayout: {},
            itemStyle: {
              borderWidth: 3,
            },
          };
        } else {
          return {
            r0: radiusPerLevel * index + '%',
            r: radiusPerLevel * (index + 1) + '%',
            label: {
              // width: 70,
              overflow: 'truncate',
              ellipsis: '...',
              align: 'right',
              formatter: '{b}: {c}',
            },
          };
        }
      }),
    ];
    //@TODO: children format original data
    return optionsCopy;
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    return {
      data: groupData(
        options?.response?.aggregation_value,
        options?.payload?.groupByFields,
        options.drillDownAggregationField,
        this.translationModulePrefix
      ),
      chartTitle: null,
    };
  }
  //implemented in variation
  getOptions(): EchartFieldDefinition[] {
    //return defined() values
    return [];
    // return [{key:'ANCESTOR',type:'dropdown',availableValues:[],defaultSelected:'',valueFormatter:()=>{return EchartOption}}]//fieldDefinition;
  }
}
