import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AggregateFunctionOptions, AssignChartDataOptions, EchartBase } from '../../echart-base';
import { getGroupFieldIdKey, groupData } from '../../helpers/general-functions';

export class RadarChart extends EchartBase {
  chartOptions: EChartsOption = {
    radar: {
      // shape: 'circle',
      indicator: [],
      center: ['50%', '58%'],
      radius: '60%',
    },
    series: [
      {
        name: '',
        type: 'radar',
        data: [
          {
            value: [],
            name: '',
          },
        ],
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'RADAR';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    const seriesObj = options?.data?.find((x) => x?.['series']);
    const indicatorObj = options?.data?.find((x) => x?.['indicator']);
    if (seriesObj) {
      optionsCopy.series[0].data = seriesObj.series;
      (optionsCopy.radar as any).indicator = indicatorObj.indicator;
    } else {
      optionsCopy.series[0].name = options?.name;
      //   const maxVal = maxBy(data, 'value').value + 1; //@TODO restore if u want to add an extra spacing for the radar chart
      optionsCopy.series[0].data = [
        {
          value: options?.data.map((x, i) => {
            return x.value;
          }),
          name: name,
        },
      ];
      (optionsCopy.radar as any).indicator = options?.data.map((x, i) => {
        return { name: x.name }; //, max: maxVal };
      });
    }
    return optionsCopy;
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    if (options?.payload?.groupByFields?.length > 1) {
      const data = groupData(
        options?.response?.aggregation_value,
        options?.payload?.groupByFields,
        options?.drillDownAggregationField,
        this.translationModulePrefix
      );
      let secondGroupValues = {};
      let maxValue = -Infinity;
      options?.response?.aggregation_value?.forEach((val) => {
        if (val?.[options?.drillDownAggregationField] > maxValue) maxValue = val?.[options?.drillDownAggregationField];
        secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'] =
          secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE']
            ? {
                ...secondGroupValues[
                  val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'
                ],
                [val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])] || 'EMPTY_VALUE']:
                  val?.[options?.drillDownAggregationField],
              }
            : {
                [val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])] || 'EMPTY_VALUE']:
                  val?.[options?.drillDownAggregationField],
              };
        // secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])]] ? [...secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])]],{[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])]]:val?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])+'_count']}] : [{[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])]]:val?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])+'_count']}];
      });
      let ret = [];
      data.forEach((item) => {
        let series = {
          value: Object.entries(secondGroupValues).map(([key, value]) => {
            return value?.[item?.key] || 0;
          }),
          name: item.name || item.key,
        };
        ret.push(series);
      });
      return {
        data: [
          { series: ret },
          {
            indicator: Object.keys(secondGroupValues).map((x, i) => {
              return { name: x, max: maxValue, axisLabel: i == 0 ? { show: true, formatter: '{value}' } : null };
            }),
          },
        ],
        chartTitle: null,
      };
    } else {
      return this.aggregateToSingleDimension(options);
    }
  }
}
