<div class="flex align-items-center justify-content-between gap-2">
  <span class="p-input-icon-left flex gap-2">
    <p-autoComplete
      placeholder="Search..."
      class="w-full"
      styleClass="pl-0 pr-0 w-full"
      [(ngModel)]="searchQ"
      [ngModelOptions]="{ standalone: true }"
      (onSelect)="onSelectItem($event)"
      [suggestions]="searchItems$ | async"
      (completeMethod)="search($event.query)"
      field="name"
      [delay]="600"
    >
      <ng-template let-option pTemplate="item">
        <div class="flex flex-1 gap-2">
          <app-badge-item
            [styleClass]="'text-xs'"
            [status]="option?.recordStatus || 'cyan'"
            [text]="option.code"
          ></app-badge-item>
          <div>{{ option.name }}</div>
        </div>
      </ng-template>
    </p-autoComplete>
    @if (showAddItem) {
      <div class="flex-grow">
        <app-button [action]="showAddItemAction"></app-button>
      </div>
    }
    <!-- <input appMaxCharacterValidator (keyup)="search(getSearchValue($event))" class="w-full" pInputText type="text" placeholder="Search Items..." /> -->
  </span>
</div>
