import { ChartViewConfig, GroupingField } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AggregateFunctionOptions, AssignChartDataOptions, EchartBase } from '../../echart-base';
import { groupData, humanizeCasedString } from '../../helpers/general-functions';

export class SankeyChart extends EchartBase {
  chartOptions: EChartsOption = {
    backgroundColor: 'transparent',
    animationDuration: 1500,
    animationDurationUpdate: 1500,
    universalTransition: true,
    series: [
      {
        type: 'sankey',
        animationDuration: 1500,
        animationDurationUpdate: 1500,
        universalTransition: true,
        emphasis: {
          focus: 'adjacency',
        },
        top: '55',
        data: [],
        links: [],
        lineStyle: {
          color: 'source',
          curveness: 0.5,
        },
        // itemStyle: {
        //   color: '#1f77b4',
        //   borderColor: '#1f77b4'
        // },
        label: {
          //   color: 'rgba(0,0,0,0.7)',
          fontFamily: 'Arial',
          fontSize: 10,
          overflow: 'truncate',
          ellipsis: '...',
          width: 50,
        },
        draggable: false,
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'SANKEY';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    optionsCopy.series[0].name = null; //options?.name;
    const transformedTree = this.transformTree(options?.data);
    optionsCopy.series[0].data = transformedTree?.nodes;
    optionsCopy.series[0].links = transformedTree?.links;
    //@TODO: children format original data
    return optionsCopy;
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    return {
      data: groupData(
        options?.response?.aggregation_value,
        options?.payload?.groupByFields,
        options?.drillDownAggregationField,
        this.translationModulePrefix
      ),
      chartTitle: null,
    };
  }
  transformTree(tree: TreeNode[]): { nodes: Node[]; links: Link[] } {
    const nodes: Node[] = [];
    const links: Link[] = [];

    function traverse(node: TreeNode, depth: number, parentName?: string, parentNode?: TreeNode) {
      const nodeNameKey = humanizeCasedString(node?.field) + ': ' + node?.name?.toString();
      if (!nodes.find((x) => x?.name == nodeNameKey)) {
        nodes.push({
          name: nodeNameKey,
          depth,
          nodeData: { field: node?.field, fieldData: node?.fieldData, fieldGroupByValue: node?.name?.toString() },
          label: {
            show: true,
            formatter: node?.name?.toString(),
          },
        });
      }

      if (parentName) {
        links.push({
          source: parentName.toString(),
          target: nodeNameKey,
          value: node.value,
          extraData: {
            sourceNode: {
              field: parentNode?.field,
              fieldData: parentNode?.fieldData,
              fieldGroupByValue: parentNode?.name?.toString(),
            },
            targetNode: { field: node?.field, fieldData: node?.fieldData, fieldGroupByValue: node?.name?.toString() },
          },
        });
      }

      if (node?.children?.length > 0) {
        for (const child of node.children) {
          if (child?.name) {
            traverse(child, depth + 1, nodeNameKey, node);
          }
        }
      }
    }

    for (const rootNode of tree) {
      traverse(rootNode, 0);
    }

    return { nodes, links };
  }
}
interface TreeNode {
  name?: string;
  value?: number;
  field?: string;
  fieldGroupByValue?: string;
  fieldData?: GroupingField;
  children?: TreeNode[];
}

interface Node {
  name: string;
  depth: number;
  nodeData: TreeNode;
  label: { show: boolean; [x: string]: any };
}

interface Link {
  source: string;
  target: string;
  value: number;
  extraData: { sourceNode: TreeNode; targetNode: TreeNode };
}
