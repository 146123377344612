<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-number-input
        [label]="moduleFieldString + '.daysToKeep.label' | translate: 'Days To Keep'"
        [placeholder]="moduleFieldString + '.daysToKeep.placeholder' | translate: 'Enter Days To Keep'"
        [control]="formGroup?.controls?.daysToKeep"
        [viewMode]="fieldViewMode"
      ></app-number-input>
    </div>
  </div>
  <div class="flex w-full flex-column align-items-end">
    <app-button class="w-fit" [action]="submitSaveButtonAction"></app-button>
  </div>
  <ng-content></ng-content>
</form>
