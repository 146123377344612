<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      @if (isTextEditor) {
        <app-text-editor
          [height]="'100px'"
          [advanced]="true"
          [name]="'description'"
          [label]="label"
          [placeholder]="placeholder"
          [control]="formGroup?.controls?._field"
          [viewMode]="fieldViewMode"
        ></app-text-editor>
      } @else {
        <app-basic-input
          [label]="label"
          [placeholder]="placeholder"
          [control]="formGroup?.controls?._field"
          [viewMode]="'edit'"
        ></app-basic-input>
      }
    </div>
    @if (this.languageDataService.loadingAllLanguagesState === true) {
      <div class="flex flex-column gap-3 w-full">
        <div class="flex align-items-end gap-3 col-12">
          <div style="flex: 1">
            <p-skeleton width="100%" height="2.5rem" styleClass="" />
          </div>
        </div>
      </div>
    }
    @if (this.languageDataService.loadingAllLanguagesState === 'error') {
      <p>{{ 'general.messages.errorLoadingLanguages' | translate }}</p>
      <app-button [action]="reloadLanguagesAction"></app-button>
    }
    @for (field of textFields; track field; let i = $index) {
      <div class="col-12">
        <ng-template appDynamicComponent [mode]="'edit'" [dynamicComponentInfo]="field"></ng-template>
      </div>
    }
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      <app-button [action]="submitButtonAction"></app-button>
      <app-button [action]="cancelButtonAction"></app-button>
    </div>
  </div>
</form>
