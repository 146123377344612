<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
>
  @if (dropDown) {
    @if (viewMode == 'create') {
      <app-input-view-switch
        [mode]="viewMode"
        [data]="
          data?.name ||
          control?.value?.name ||
          data ||
          control?.value?.label ||
          control?.value?.name ||
          control?.value?.value ||
          control?.value
        "
        [dataType]="dataType"
        [label]="label"
      >
        <span
          class="field w-full"
          [class.p-float-label]="floatLabel"
          [class.mb-1]="control?.dirty && this.control.errors"
        >
          <p-treeSelect
            [options]="formattedEntities"
            [selectionMode]="multi ? 'multiple' : 'single'"
            [(ngModel)]="selectedNodes"
            placeholder="Select Item"
            [filter]="true"
            [filterInputAutoFocus]="true"
            (ngModelChange)="onValueChanges($event)"
            (onNodeUnselect)="onNodeUnselect($event)"
            [showClear]="showClear"
            [disabled]="control?.disabled"
            [metaKeySelection]="false"
            [appendTo]="'body'"
            [class.ng-invalid]="fControl?.invalid"
            [class.ng-dirty]="fControl?.dirty"
          >
            <ng-template let-node pTemplate="root">
              <div class="">
                <span class="h-fit">
                  {{ node.label }}
                </span>
              </div>
            </ng-template>
            <ng-template let-node pTemplate="default">
              <div class="w-full flex gap-4 justify-content-between align-items-center">
                <span class="h-fit flex align-items-center">
                  @if (node?.data?.code) {
                    <app-badge-item
                      class="mr-2"
                      [styleClass]="'text-xs'"
                      [pTooltip]="
                        !(node?.data?.members?.length > 0)
                          ? 'No Members'
                          : node?.data?.isMember
                            ? 'You are a Member in this group'
                            : ''
                      "
                      [status]="
                        node?.data?.code?.startsWith('RES') && !(node?.data?.members?.length > 0)
                          ? 'red'
                          : node?.data?.isMember
                            ? 'gold'
                            : node?.data?.recordStatus || 'gray'
                      "
                      [flicker]="node?.data?.code?.startsWith('RES') && !(node?.data?.members?.length > 0)"
                      [text]="node?.data?.code"
                      [customIcon]="node?.data?.recordStatus ? badgeIcons[node?.data?.recordStatus] : null"
                    ></app-badge-item>
                  }
                  <!-- <app-badge-item *ngIf="node?.data?.code?.startsWith('RES') && !(node?.data?.members?.length > 0)" class="mr-2" [styleClass]="'text-xs'" [status]="'ERROR'" [text]="'No Members'" [flicker]="true"></app-badge-item> -->
                  <p>{{ node.label }}</p>
                </span>
                @if (useResponsibilityMode) {
                  <div>
                    <p-selectButton
                      [options]="responsibilityModeOptions"
                      [(ngModel)]="node.data.mode"
                      optionLabel="label"
                      optionValue="value"
                      (onOptionClick)="onOptionClick($event)"
                      (onChange)="onChangeRespMode($event, node)"
                      styleClass="resp-mode-buttons"
                    >
                      <ng-template let-item>
                        <div
                          class="flex flex-row gap-2 align-items-center w-full align-self-center justify-content-center"
                          [pTooltip]="item.tooltip"
                          tooltipPosition="top"
                          style="padding: 0.629rem 0"
                        >
                          <i [class]="item.icon"></i>
                          @if (item.label) {
                            <div>
                              {{ item.label }}
                            </div>
                          }
                        </div>
                      </ng-template>
                    </p-selectButton>
                  </div>
                }
              </div>
            </ng-template>
          </p-treeSelect>
          @if (floatLabel) {
            <label [for]="name">
              {{ label }}
              @if (isControlRequired) {
                <span class="text-red-500"> *</span>
              }
              <span [class]="isLoading ? 'ml-2 pi pi-spin pi-spinner' : ''"></span
            ></label>
          }
        </span>
        @if (control?.dirty) {
          @if (control?.hasError('required')) {
            <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
          }
          @if (customError && control?.invalid) {
            <small class="p-error p-invalid">{{ customErrorLabel }}</small>
          }
        }
        @if (helpText) {
          <small class="text-500 block">{{ helpText }}</small>
        }
      </app-input-view-switch>
    } @else {
      <div class="field" [class.mb-1]="editModalControl?.dirty && this.editModalControl.errors">
        <span class="p-float-label p-inputgroup flex flex-row relative hide-selection">
          <input
            appMaxCharacterValidator
            class="w-full"
            [type]="'text'"
            pInputText
            readonly
            [formControl]="editModalControl"
            [placeholder]="label"
            (focus)="onFocusChange($event)"
            class="cursor-pointer"
            (click)="openEditModal()"
            style="color: transparent"
          />
          <label
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
          </label>
          <div
            (click)="openEditModal()"
            class="p-inputtext"
            style="background: transparent; width: calc(100% - 41px); position: absolute; height: 100%; top: 0; left: 0"
          >
            @if (editModalControl?.value) {
              <app-code-navigation [showTooltip]="true" [data]="editModalControl?.value"></app-code-navigation>
            }
          </div>
          @if (!fControl?.disabled) {
            <button
              type="button"
              pButton
              (click)="openEditModal()"
              class="white-space-nowrap"
              [icon]="'pi pi-pencil'"
            ></button>
          }
        </span>
        @if (editModalControl?.dirty) {
          @if (editModalControl?.hasError('required')) {
            <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
          }
        }
      </div>
    }
  } @else {
    @if (isLoading) {
      <div class="w-full">
        <span [class]="isLoading ? 'ml-2 pi pi-spin pi-spinner' : ''"></span>
      </div>
    }
    <p-organizationChart
      (selectionChange)="onValueChanges($event)"
      [value]="formattedEntities"
      [selectionMode]="multi ? 'multiple' : 'single'"
      [(selection)]="selectedNodes"
    ></p-organizationChart>
  }
</app-input-view-switch>

<p-dialog
  [header]="label"
  [(visible)]="editModalVisible"
  [modal]="true"
  [style]="{ width: '47vw' }"
  [draggable]="false"
  [resizable]="true"
>
  @if (editModalVisible) {
    <app-input-view-switch
      [mode]="viewMode"
      [data]="
        data?.name ||
        control?.value?.name ||
        data ||
        control?.value?.label ||
        control?.value?.name ||
        control?.value?.value ||
        control?.value
      "
      [dataType]="dataType"
      [label]="label"
    >
      @if (floatLabel && !editModalVisible) {
        <label [for]="name">
          {{ label }}
          @if (isControlRequired) {
            <span class="text-red-500"> *</span>
          }
          <span [class]="isLoading ? 'ml-2 pi pi-spin pi-spinner' : ''"></span
        ></label>
      }
      <span class="field w-full" [class.p-float-label]="floatLabel">
        <p-tree
          [value]="formattedEntities"
          [selectionMode]="multi ? 'multiple' : 'single'"
          [(selection)]="selectedNodes"
          placeholder="Select Item"
          [filter]="true"
          (selectionChange)="onValueChanges($event)"
          (onNodeUnselect)="onNodeUnselect($event)"
          [metaKeySelection]="false"
          [styleClass]="'border-none'"
        >
          <ng-template let-node pTemplate="root">
            <div class="">
              <span class="h-fit">
                {{ node.label }}
              </span>
            </div>
          </ng-template>
          <ng-template let-node pTemplate="default">
            <div class="w-full flex gap-4 justify-content-between align-items-center">
              <span class="h-fit flex align-items-center">
                @if (node?.data?.code) {
                  <app-badge-item
                    class="mr-2"
                    [styleClass]="'text-xs'"
                    [pTooltip]="
                      !(node?.data?.members?.length > 0)
                        ? 'No Members'
                        : node?.data?.isMember
                          ? 'You are a Member in this group'
                          : ''
                    "
                    [status]="
                      node?.data?.code?.startsWith('RES') && !(node?.data?.members?.length > 0)
                        ? 'red'
                        : node?.data?.isMember
                          ? 'gold'
                          : node?.data?.recordStatus || 'gray'
                    "
                    [flicker]="node?.data?.code?.startsWith('RES') && !(node?.data?.members?.length > 0)"
                    [text]="node?.data?.code"
                    [customIcon]="node?.data?.recordStatus ? badgeIcons[node?.data?.recordStatus] : null"
                  ></app-badge-item>
                }
                <!-- <app-badge-item *ngIf="node?.data?.code" class="mr-2" [styleClass]="'text-xs'" [status]="node?.data?.recordStatus || 'gray'" [text]="node?.data?.code"></app-badge-item>
                  <app-badge-item *ngIf="node?.data?.code?.startsWith('RES') && !(node?.data?.members?.length > 0)" class="mr-2" [styleClass]="'text-xs'" [status]="'ERROR'" [text]="'No Members'"></app-badge-item> -->
                <p>{{ node.label }}</p>
              </span>
              @if (useResponsibilityMode) {
                <div>
                  <p-selectButton
                    [options]="responsibilityModeOptions"
                    [(ngModel)]="node.data.mode"
                    optionLabel="label"
                    optionValue="value"
                    (onOptionClick)="onOptionClick($event)"
                    (onChange)="onChangeRespMode($event, node)"
                    styleClass="resp-mode-buttons"
                  >
                    <ng-template let-item>
                      <div
                        class="flex flex-row gap-2 align-items-center w-full align-self-center justify-content-center"
                        [pTooltip]="item.tooltip"
                        tooltipPosition="top"
                        style="padding: 0.629rem 0"
                      >
                        <i [class]="item.icon"></i>
                        @if (item.label) {
                          <div>
                            {{ item.label }}
                          </div>
                        }
                      </div>
                    </ng-template>
                  </p-selectButton>
                </div>
              }
            </div>
          </ng-template>
        </p-tree>
      </span>
      @if (control?.dirty) {
        @if (control?.hasError('required')) {
          <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
        }
        @if (customError && control?.invalid) {
          <small class="p-error p-invalid">{{ customErrorLabel }}</small>
        }
      }
      @if (helpText) {
        <small class="text-500 block">{{ helpText }}</small>
      }
    </app-input-view-switch>
  }
</p-dialog>
