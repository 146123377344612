import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, IAction } from '@shared/classes';
import { AppDialogService, BaseFieldDefinitionsService, BaseRequestControllerService } from '@shared/services';
import { FieldTranslationsComponent } from '../field-translations/field-translations.component';

@Component({
  selector: 'app-field-translations-button',
  templateUrl: './field-translations-button.component.html',
  styleUrl: './field-translations-button.component.scss',
})
export class FieldTranslationsButtonComponent implements OnInit {
  showFieldTranslationsFormAction: IAction = {
    id: 1,
    label: '',
    buttonType: 'button',
    command: this.showFieldTranslationsForm.bind(this),
    icon: 'pi pi-language',
    tooltipText: 'Translate Field',
    buttonStyle: 'text',
    color: 'secondary',
    buttonClass: 'p-0',
  };
  @Input() options: FieldTranslationsOptions;
  constructor(private appDialogService: AppDialogService) {}
  ngOnInit(): void {}

  showFieldTranslationsForm() {
    console.log(this.options);
    this.appDialogService.showDialog(
      FieldTranslationsComponent,
      'Translate Field',
      (data) => {
        console.log(data);
        // this.loadData();
      },
      {
        data: {
          ...this.options,
        },
      }
    );
  }
}
export interface FieldTranslationsOptions {
  itemCode: string;
  fieldKey: string;
  label: string;
  placeholder: string;
  textFieldType: DataTypeEnum.Text | DataTypeEnum.LongText;
  formData: any;
  dataService: BaseRequestControllerService<any>;
  mappingService?: BaseFieldDefinitionsService;
  options?: any;
}
