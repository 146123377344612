import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm, SettingDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-config-item-form',
  templateUrl: './config-item-form.component.html',
  styleUrl: './config-item-form.component.scss',
})
export class ConfigItemFormComponent extends BaseForm<SettingDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, null);
  }

  ngOnInit(): void {}

  getData() {
    return this.getDataKeyValueFormat({
      deletionRetentionSettings: this.formGroup.controls.daysToKeep?.getRawValue(),
    }).createItems;
  }

  setData(data: any) {
    this.data = data;
    this.formGroup.patchValue({
      daysToKeep: this.data?.deletionRetentionSettings?.daysToKeep ?? 0,
    });
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      daysToKeep: new FormControl(null),
    });
  }
  get moduleFieldString(): string {
    return `general.settings.configuration.fields`;
  }
  get moduleString(): string {
    return `general.settings.configuration`;
  }
}
