<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
>
  @if (viewMode == 'create') {
    @if (dropDown) {
      <app-input-view-switch
        [mode]="viewMode"
        [data]="
          data?.name ||
          control?.value?.name ||
          data ||
          control?.value?.label ||
          control?.value?.name ||
          control?.value?.value ||
          control?.value
        "
        [dataType]="dataType"
        [label]="label"
      >
        <span
          class="field w-full"
          [class.p-float-label]="floatLabel"
          [class.mb-1]="control?.dirty && this.control.errors"
        >
          <p-treeSelect
            [options]="formattedItems"
            [selectionMode]="multi ? 'multiple' : 'single'"
            [(ngModel)]="selectedNodes"
            placeholder="Select Item"
            [filter]="true"
            [filterInputAutoFocus]="true"
            (ngModelChange)="onValueChanges($event)"
            (onNodeUnselect)="onNodeUnselect($event)"
            [showClear]="true"
            [metaKeySelection]="false"
            [appendTo]="'body'"
            [class.ng-invalid]="fControl?.invalid"
            [class.ng-dirty]="fControl?.dirty"
            [disabled]="!!linkedControl?.invalid || linkedControl?.disabled"
          >
            <ng-template let-node pTemplate="root">
              <div class="">
                <span class="h-fit">
                  {{ node.label }}
                </span>
              </div>
            </ng-template>
            <ng-template let-node pTemplate="default">
              <div class="w-full flex gap-4 justify-content-between align-items-center">
                <span class="h-fit flex align-items-center">
                  @if (node?.data?.code) {
                    <app-badge-item
                      class="mr-2"
                      [styleClass]="'text-xs'"
                      [status]="node?.data?.recordStatus || 'gray'"
                      [text]="node?.data?.code"
                    ></app-badge-item>
                  }
                  <p>{{ node.label }}</p>
                </span>
              </div>
            </ng-template>
          </p-treeSelect>
          @if (floatLabel && !editModalVisible) {
            <label [for]="name">
              {{ label }}
              @if (isControlRequired) {
                <span class="text-red-500"> *</span>
              }
              <span [class]="isLoading ? 'ml-2 pi pi-spin pi-spinner' : ''"></span
            ></label>
          }
        </span>
        @if (control?.dirty) {
          @if (control?.hasError('required')) {
            <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
          }
          @if (customError && control?.invalid) {
            <small class="p-error p-invalid">{{ customErrorLabel }}</small>
          }
        }
        @if (helpText) {
          <small class="text-500 block">{{ helpText }}</small>
        }
      </app-input-view-switch>
    } @else {
      @if (isLoading) {
        <div class="w-full">
          <span [class]="isLoading ? 'ml-2 pi pi-spin pi-spinner' : ''"></span>
        </div>
      }
      <p-organizationChart
        (selectionChange)="onValueChanges($event)"
        [value]="formattedItems"
        [selectionMode]="multi ? 'multiple' : 'single'"
        [(selection)]="selectedNodes"
      ></p-organizationChart>
    }
  } @else {
    <div class="field" [class.mb-1]="editModalControl?.dirty && this.editModalControl.errors">
      <span class="p-float-label p-inputgroup flex flex-row relative hide-selection">
        <input
          appMaxCharacterValidator
          class="w-full"
          [type]="'text'"
          pInputText
          readonly
          [formControl]="editModalControl"
          [placeholder]="label"
          (focus)="onFocusChange($event)"
          class="cursor-pointer"
          (click)="openEditModal()"
          style="color: transparent"
        />
        <label
          >{{ label }}
          @if (isControlRequired) {
            <span class="text-red-500"> *</span>
          }
        </label>
        <div
          (click)="openEditModal()"
          class="p-inputtext"
          style="background: transparent; width: calc(100% - 41px); position: absolute; height: 100%; top: 0; left: 0"
        >
          @if (editModalControl?.value) {
            <app-code-navigation [showTooltip]="true" [data]="editModalControl?.value"></app-code-navigation>
          }
        </div>
        @if (!fControl?.disabled) {
          <button
            type="button"
            pButton
            (click)="openEditModal()"
            class="white-space-nowrap"
            [icon]="'pi pi-pencil'"
          ></button>
        }
      </span>
      @if (editModalControl?.dirty) {
        @if (editModalControl?.hasError('required')) {
          <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
        }
      }
    </div>
  }
</app-input-view-switch>

<p-dialog
  [header]="label"
  [(visible)]="editModalVisible"
  [modal]="true"
  [style]="{ width: '80vw' }"
  [draggable]="false"
  [resizable]="true"
>
  @if (editModalVisible) {
    <app-input-view-switch
      [mode]="viewMode"
      [data]="
        data?.name ||
        control?.value?.name ||
        data ||
        control?.value?.label ||
        control?.value?.name ||
        control?.value?.value ||
        control?.value
      "
      [dataType]="dataType"
      [label]="label"
    >
      @if (floatLabel && !editModalVisible) {
        <label [for]="name">
          {{ label }}
          @if (isControlRequired) {
            <span class="text-red-500"> *</span>
          }
          <span [class]="isLoading ? 'ml-2 pi pi-spin pi-spinner' : ''"></span
        ></label>
      }
      <span class="field w-full" [class.p-float-label]="floatLabel">
        <p-tree
          [value]="formattedItems"
          [selectionMode]="multi ? 'multiple' : 'single'"
          [(selection)]="selectedNodes"
          placeholder="Select Item"
          [filter]="true"
          (selectionChange)="onValueChanges($event)"
          (onNodeUnselect)="onNodeUnselect($event)"
          [metaKeySelection]="false"
          [styleClass]="'border-none'"
        >
          <ng-template let-node pTemplate="root">
            <div class="">
              <span class="h-fit">
                {{ node.label }}
              </span>
            </div>
          </ng-template>
          <ng-template let-node pTemplate="default">
            <div class="w-full flex gap-4 justify-content-between align-items-center">
              <span class="h-fit flex align-items-center">
                @if (node?.data?.code) {
                  <app-badge-item
                    class="mr-2"
                    [styleClass]="'text-xs'"
                    [status]="node?.data?.recordStatus || 'gray'"
                    [text]="node?.data?.code"
                  ></app-badge-item>
                }
                <p>{{ node.label }}</p>
              </span>
            </div>
          </ng-template>
        </p-tree>
      </span>
      @if (control?.dirty) {
        @if (control?.hasError('required')) {
          <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
        }
        @if (customError && control?.invalid) {
          <small class="p-error p-invalid">{{ customErrorLabel }}</small>
        }
      }
      @if (helpText) {
        <small class="text-500 block">{{ helpText }}</small>
      }
    </app-input-view-switch>
  }
</p-dialog>
