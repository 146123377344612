<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        label="iso2013"
        [placeholder]="'Enter iso2013'"
        [control]="formGroup?.controls?.iso2013"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-6">
      <app-basic-input
        label="iso2022"
        [placeholder]="'Enter iso2022'"
        [control]="formGroup?.controls?.iso2022"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-6">
      <app-basic-input
        label="csf"
        [placeholder]="'Enter csf'"
        [control]="formGroup?.controls?.csf"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-6">
      <app-basic-input
        label="cscTop20"
        [placeholder]="'Enter cscTop20'"
        [control]="formGroup?.controls?.cscTop20"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-6">
      <app-basic-input
        label="nesa"
        [placeholder]="'Enter nesa'"
        [control]="formGroup?.controls?.nesa"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-12 mb-2">
      <span class="p-input-icon-right flex gap-2 w-full">
        <p-autoComplete
          placeholder="Search ..."
          class="btnAddQ w-full"
          styleClass="pl-0 pr-0"
          [(ngModel)]="searchQ"
          [ngModelOptions]="{ standalone: true }"
          (onSelect)="onSelectItem($event)"
          [suggestions]="searchQuestions$ | async"
          (completeMethod)="search($event.query)"
          field="name"
          [delay]="500"
          [multiple]="true"
        >
        </p-autoComplete>
        <i class="pi pi-search" *ngIf="!isSearching"></i>

        <div class="flex-grow5">
          <app-button [action]="showAddQuestionAction"></app-button>
        </div>
        <!-- <input appMaxCharacterValidator (keyup)="search(getSearchValue($event))" class="w-full" pInputText type="text" placeholder="Search Questions..." /> -->
      </span>
    </div>
    <div class="col-12 md:col-12">
      <span class="p-input-icon-right flex gap-2 w-full">
        <textarea pInputTextarea [rows]="10" [(ngModel)]="bulkItems" [ngModelOptions]="{ standalone: true }"></textarea>

        <div class="flex-grow5">
          <app-button [action]="showAddQuestionActionBulk"></app-button>
        </div>
        <!-- <input appMaxCharacterValidator (keyup)="search(getSearchValue($event))" class="w-full" pInputText type="text" placeholder="Search Questions..." /> -->
      </span>
    </div>
  </div>

  <!-- <div class="flex flex-row-reverse gap-2">
        <app-button *ngIf="fieldViewMode != 'view'" [action]="submitButtonAction"></app-button>
        <app-button *ngIf="showSaveButton" [action]="submitSaveButtonAction"></app-button>
    </div> -->
</form>

<!-- <h6>Checkbox-Only Selection</h6> -->
<p-table
  [value]="products"
  editMode="row"
  [(selection)]="selectedProducts3"
  dataKey="id.id"
  [tableStyle]="{ 'min-width': '50rem' }"
  (onRowSelect)="onRowSelect($event)"
>
  <ng-template pTemplate="caption">
    <div class="table-header">
      <div class="flex-grow5 flex flex-row-reverse justify-content-between w-full">
        <app-button *ngIf="fieldViewMode != 'view'" [action]="submitButtonAction"></app-button>
        <app-button *ngIf="showSaveButton" [action]="submitSaveButtonAction"></app-button>
        <app-button *ngIf="products?.length > 0" [action]="clearTableAction"></app-button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem">select</th>
      <th>search Score</th>
      <th>Name</th>
      <th>Iso2013</th>
      <th>Iso2022</th>
      <th>Csf</th>
      <th>CscTop20</th>
      <th>Nesa</th>
      <th style="width: 20%"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
    <tr [pEditableRow]="product">
      <td>
        <p-tableCheckbox [value]="product"></p-tableCheckbox>
      </td>

      <td [style.background-color]="product?.searchScore?.color ?? ''">
        <p-cellEditor>
          <ng-template pTemplate="output">
            {{ product.searchScore.searchScore }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input appMaxCharacterValidator pInputText type="text" [(ngModel)]="product.name.name" />
          </ng-template>
          <ng-template pTemplate="output">
            {{ product.name.name }}
          </ng-template>
        </p-cellEditor>
      </td>

      <td [style.background-color]="product?.iso2013.color ?? ''">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input appMaxCharacterValidator pInputText type="text" [(ngModel)]="product.iso2013.iso2013" />
          </ng-template>
          <ng-template pTemplate="output">
            <div>
              {{ product.iso2013.iso2013 }}
            </div>
          </ng-template>
        </p-cellEditor>
      </td>

      <td [style.background-color]="product?.iso2022.color ?? ''">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input appMaxCharacterValidator pInputText type="text" [(ngModel)]="product.iso2022.iso2022" />
          </ng-template>
          <ng-template pTemplate="output">
            <div>
              {{ product.iso2022.iso2022 }}
            </div>
          </ng-template>
        </p-cellEditor>
      </td>

      <td [style.background-color]="product?.csf.color ?? ''">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input appMaxCharacterValidator pInputText type="text" [(ngModel)]="product.csf.csf" />
          </ng-template>
          <ng-template pTemplate="output">
            <div>
              {{ product.csf.csf }}
            </div>
          </ng-template>
        </p-cellEditor>
      </td>

      <td [style.background-color]="product?.cscTop20.color ?? ''">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input appMaxCharacterValidator pInputText type="text" [(ngModel)]="product.cscTop20.cscTop20" />
          </ng-template>
          <ng-template pTemplate="output">
            <div>
              {{ product.cscTop20.cscTop20 }}
            </div>
          </ng-template>
        </p-cellEditor>
      </td>

      <td [style.background-color]="product?.nesa.color ?? ''">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input appMaxCharacterValidator pInputText type="text" [(ngModel)]="product.nesa.nesa" />
          </ng-template>
          <ng-template pTemplate="output">
            <div>
              {{ product?.nesa.nesa }}
            </div>
          </ng-template>
        </p-cellEditor>
      </td>

      <td *ngIf="selectedProduct(product)">
        <div class="flex align-items-center justify-content-center gap-2">
          <button
            *ngIf="!editing"
            pButton
            pRipple
            type="button"
            pInitEditableRow
            icon="pi pi-pencil"
            (click)="onRowEditInit(product)"
            class="p-button-rounded p-button-text"
          ></button>
          <button
            *ngIf="!editing"
            pButton
            pRipple
            type="button"
            icon="pi pi-trash"
            (click)="onRowDelete(product, $event)"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pSaveEditableRow
            icon="pi pi-check"
            (click)="onRowEditSave(product)"
            class="p-button-rounded p-button-text p-button-success mr-2"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pCancelEditableRow
            icon="pi pi-times"
            (click)="onRowEditCancel(product, ri)"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
