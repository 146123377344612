import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AggregateFunctionOptions, AssignChartDataOptions } from '../../../echart-base';
import { EchartVariationBase } from '../../../echart-variation-base';
import { getGroupFieldIdKey, groupData } from '../../../helpers/general-functions';
import { TimescaleChart } from '../../base/timescale-chart';

export class TimescaleBarRaceChart extends TimescaleChart implements EchartVariationBase {
  variation: ChartViewConfig.ChartVariationEnum = 'STANDARD';
  chartOptions: EChartsOption = {
    // baseOption:{
    timeline: {
      playInterval: 4000,
      axisType: 'category',
      autoPlay: true,
      data: [],
      // label: {
      //   formatter: function (s) {
      //     const date = new Date(s);
      //     const year = date?.getFullYear();
      //     const month = date?.getMonth() + 1; // Months are zero-based, so add 1
      //     const day = date?.getDate();
      //     const hours = this.formatNumberWithLeadingZero(date?.getHours());
      //     const minutes = this.formatNumberWithLeadingZero(date?.getMinutes());
      //     // Construct the human-friendly format
      //     const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
      //     return formattedDate;
      //   },
      // },
    },
    xAxis: [
      {
        max: 'dataMax',
      },
    ],
    yAxis: [
      {
        type: 'category',
        data: [],
        inverse: true,
        animationDuration: 300,
        animationDurationUpdate: 300,
        // max: 2 // only the largest 3 bars will be displayed
      },
    ],
    series: [
      {
        realtimeSort: true,
        name: '',
        type: 'bar',
        data: [],
        label: {
          show: true,
          position: 'right',
          valueAnimation: true,
        },
      },
    ],
    legend: {
      show: false,
      top: 45,
    },
    animationDuration: 0,
    animationDurationUpdate: 3000,
    animationEasing: 'linear',
    animationEasingUpdate: 'linear',
    // }
  };
  checkVariationCondition(variation: ChartViewConfig.ChartVariationEnum): boolean {
    return true;
  }
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    const seriesObj = options?.data?.find((x) => x?.['options']);
    const dateList = options?.data?.find((x) => x?.['dateList']);
    const yAxisData = options?.data?.find((x) => x?.['yAxisData']);
    if (seriesObj) {
      optionsCopy.options = seriesObj.options;
      optionsCopy.timeline.data = seriesObj.dateList;
      optionsCopy.yAxis[0].data = seriesObj.yAxisData;
    } else {
      optionsCopy.series[0].name = options?.name;
      optionsCopy.series[0].data = options?.data.map((x, i) => {
        return { ...x };
      });
      optionsCopy.yAxis[0].data = options?.data.map((x, i) => {
        return x.name;
      });
    }
    return optionsCopy;
  }
  parseAndFormatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date?.getFullYear();
    const month = date?.getMonth() + 1; // Months are zero-based, so add 1
    const day = date?.getDate();
    const hours = this.formatNumberWithLeadingZero(date?.getHours());
    const minutes = this.formatNumberWithLeadingZero(date?.getMinutes());

    // Construct the human-friendly format
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

    return formattedDate;
  }
  formatNumberWithLeadingZero(num: number): string {
    // Assuming num is between 0 and 99
    return num < 10 ? `0${num}` : num.toString();
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    const statistics: { date: string; data: { value: number; name: string }[]; chartTitle: string }[] = [];
    const statisticsObject: { [x: string]: number[] } = {}; //this will store [series key]:{series specs + data array}
    const yAxisData = {};
    options?.collectedStatistics?.forEach((item) => {
      const rt = {
        ...this.aggregateToSingleDimension({ ...item, drillDownAggregationField: options?.drillDownAggregationField }),
        date: item.collectionDate,
      };
      statistics.push(rt);
      rt.data.forEach((it) => {
        yAxisData[it?.name] = it.value;
      });
    });
    statistics.forEach((element) => {
      if (element.data) {
        element.data.forEach((item) => {
          // add date,value to the series
          statisticsObject[item.name] = statisticsObject[item.name]
            ? [...statisticsObject[item.name], item.value]
            : [item.value];
        });
      }
    });
    const seriesList = [];
    statistics.forEach((value) => {
      seriesList.push({
        title: {
          text: this.parseAndFormatDate(value.date),
        },
        series: {
          realtimeSort: true,
          type: 'bar',
          label: {
            show: true,
            position: 'right',
            valueAnimation: true,
          },
          // barWidth: '60%',
          name: value.chartTitle,
          data: value.data,
        },
      });
    });
    return {
      data: [
        {
          options: seriesList,
          dateList: statistics.map((x) => this.parseAndFormatDate(x.date)),
          yAxisData: Object.keys(yAxisData),
        },
      ],
      chartTitle: null,
    };
  }
  formatAggregateDataOld(options1: AggregateFunctionOptions) {
    const options = options1?.collectedStatistics?.[0];
    if (options?.payload?.groupByFields?.length > 1 && options?.response?.aggregation_value) {
      const data = groupData(
        options?.response?.aggregation_value,
        options?.payload?.groupByFields,
        options1.drillDownAggregationField,
        this.translationModulePrefix
      );
      let secondGroupValues = {};
      options?.response?.aggregation_value?.forEach((val) => {
        secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'] =
          secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE']
            ? {
                ...secondGroupValues[
                  val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'
                ],
                [val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])] || 'EMPTY_VALUE']:
                  val?.[options1?.drillDownAggregationField],
              }
            : {
                [val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])] || 'EMPTY_VALUE']:
                  val?.[options1?.drillDownAggregationField],
              };
        // secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])]] ? [...secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])]],{[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])]]:val?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])+'_count']}] : [{[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])]]:val?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])+'_count']}];
      });
      let ret = [];
      data.forEach((item) => {
        let series = {
          data: Object.entries(secondGroupValues).map(([key, value]) => {
            return value?.[item?.key] || 0;
          }),
          realtimeSort: true,
          type: 'bar',
          label: {
            show: true,
            position: 'right',
            valueAnimation: true,
          },
          // barWidth: '60%',
          name: item.name,
        };
        ret.push(series);
      });
      return {
        data: [{ series: ret }, { yAxis: Object.keys(secondGroupValues) }],
        chartTitle: null,
      };
    } else {
      return { data: [] as any, chartTitle: null };
    }
  }
}
