import { ChartViewConfig } from '@shared/classes/model';
import { EChartsOption } from 'echarts';
import { AggregateFunctionOptions, AssignChartDataOptions, EchartBase } from '../../echart-base';
import { getGroupFieldIdKey, groupData } from '../../helpers/general-functions';

export class HorizontalBarChart extends EchartBase {
  chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      appendTo: 'body',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      top: '75',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'value',
      },
    ],
    yAxis: [
      {
        type: 'category',
        data: [],
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    series: [
      {
        name: '',
        type: 'bar',
        barWidth: '60%',
        label: {
          show: true,
          formatter: function (params): string {
            return params.value != 0 ? params.value.toString() : '';
          },
        },
        data: [],
      },
    ],
  };
  chartType: ChartViewConfig.ChartTypeEnum = 'HORIZONTAL_BAR';
  assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
    let optionsCopy = { ...options?.chartOptions };
    const seriesObj = options?.data?.find((x) => x?.['series']);
    const yAxisObj = options?.data?.find((x) => x?.['yAxis']);
    if (seriesObj) {
      optionsCopy.series = seriesObj.series;
      optionsCopy.yAxis[0].data = yAxisObj.yAxis;
    } else {
      optionsCopy.series[0].name = options?.name;
      optionsCopy.series[0].data = options?.data.map((x, i) => {
        return { ...x };
      });
      optionsCopy.yAxis[0].data = options?.data.map((x, i) => {
        return x.name;
      });
    }
    return optionsCopy;
  }
  formatAggregateData(options: AggregateFunctionOptions) {
    if (options?.payload?.groupByFields?.length > 1) {
      const data = groupData(
        options?.response?.aggregation_value,
        options?.payload?.groupByFields,
        options?.drillDownAggregationField,
        this.translationModulePrefix
      );
      let secondGroupValues = {};
      options?.response?.aggregation_value?.forEach((val) => {
        secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'] =
          secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE']
            ? {
                ...secondGroupValues[
                  val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])] || 'EMPTY_VALUE'
                ],
                [val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])] || 'EMPTY_VALUE']:
                  val?.[options?.drillDownAggregationField],
              }
            : {
                [val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])] || 'EMPTY_VALUE']:
                  val?.[options?.drillDownAggregationField],
              };
        // secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])]] ? [...secondGroupValues[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])]],{[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])]]:val?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])+'_count']}] : [{[val?.group_id?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[0])]]:val?.[getGroupFieldIdKey(options?.payload?.groupByFields?.[1])+'_count']}];
      });
      let ret = [];
      data.forEach((item) => {
        let series = {
          data: Object.entries(secondGroupValues).map(([key, value]) => {
            return value?.[item?.key] || 0;
          }),
          type: 'bar',
          // barWidth: '60%',
          name: item.name,
          label: {
            show: true,
            formatter: function (params): string {
              return params.value != 0 ? params.value.toString() : '';
            },
          },
        };
        ret.push(series);
      });
      return {
        data: [{ series: ret }, { yAxis: Object.keys(secondGroupValues) }],
        chartTitle: null,
      };
    } else {
      return this.aggregateToSingleDimension(options);
    }
  }
  // assignDataToChartOptions(options: AssignChartDataOptions): EChartsOption {
  //   let optionsCopy = { ...options?.chartOptions };
  //   optionsCopy.series[0].name = options?.name;
  //   optionsCopy.series[0].data = options?.data?.map((x, i) => {
  //     return { ...x };
  //   });
  //   optionsCopy.yAxis[0].data = options?.data?.map((x, i) => {
  //     return x.name;
  //   });
  //   return optionsCopy;
  // }
}
