import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { BaseForm, IAction, RequestHandlerOptions } from '@shared/classes';
import { AppDialogService, ViewModeService } from '@shared/services';
import { cloneDeep } from 'lodash-es';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-control-objective-add-link-popup',
  templateUrl: './control-objective-add-link-popup.component.html',
  styleUrl: './control-objective-add-link-popup.component.scss',
})
export class ControlObjectiveAddLinkPopupComponent extends BaseForm<any> implements OnInit {
  bulkItems: any = '';
  memoryMap = {};
  selectedProducts3: any;

  products: any[] = [];
  clonedProducts: any = {};

  showAddQuestionActionBulk: IAction = {
    id: 1,
    label: 'Add all',
    buttonType: 'button',
    command: this.onAddBulkQuestionClick.bind(this),
    icon: 'pi pi-plus',
    color: 'info',
  };
  clearTableAction: IAction = {
    id: 2,
    label: 'Clear',
    buttonType: 'button',
    command: this.clearTableAndSelection.bind(this),
    icon: 'pi pi-filter-slash',
    color: 'danger',
    buttonStyle: 'outlined',
  };

  confirmAddLinkAction: IAction = {
    id: 3,
    label: 'Confirm',
    buttonType: 'button',
    command: this.confirmAddLink.bind(this),
    icon: 'pi pi-save',
    // status$: this.formValid$,
    loading$: this.loadingState$,
    // buttonStyle: 'outlined'
  };
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private appDialogService: AppDialogService,
    public viewModeService: ViewModeService
  ) {
    super(viewModeService, 'CONTROL_OBJECTIVE');
    if (!this.data) this.data = {};
    this.data.answers = this.config?.data?.questionnaireValue;
    this.viewModeOnly = this.config?.data?.viewModeOnly;
    this.formData = this.data;
  }

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  getData() {
    return this.products;
  }

  setData(data) {
    this.formGroup.patchValue(data);
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      answers: new FormArray([]),
    });
  }

  onAddBulkQuestionClick() {
    console.dir(this.bulkItems.split('\n'));
    this.bulkItems
      .split('\n')
      .filter((x) => x != '')
      .forEach((element) => {
        this.addNewElement({ ...this.formGroup.getRawValue(), name: element, id: uuidv4() }, '#e91e63', true);
      });

    // this.addNewElement({...this.formGroup.getRawValue(),name:this.searchKeywords,id:uuidv4()},"#e91e63",true);
  }

  addNewElement(p: any, color: string = '', addToSelectedItems: boolean = false) {
    //@TODO apply DRY code principle using this function
    this.products = this.products ? this.products : [];
    let newP: any = {};

    newP['id'] = {
      id: p['id'] || '',
      color: color,
    };

    newP['code'] = {
      code: p['code'] || '',
      color: color,
    };

    newP['name'] = {
      name: p['name'] || '',
      color: '',
    };

    // newP['iso2013'] = {
    //   iso2013: p['iso2013'] || '',
    //   color: p['iso2013'] ? color : '',
    // };

    // newP['iso2022'] = {
    //   iso2022: p['iso2022'] || '',
    //   color: p['iso2022'] ? color : '',
    // };

    // newP['csf'] = {
    //   csf: p['csf'] || '',
    //   color: p['csf'] ? color : '',
    // };

    // newP['cscTop20'] = {
    //   cscTop20: p['cscTop20'] || '',
    //   color: p['cscTop20'] ? color : '',
    // };

    // newP['nesa'] = {
    //   nesa: p['nesa'] || '',
    //   color: p['nesa'] ? color : '',
    // };
    // newP['searchScore'] = {
    //   searchScore: 0,
    //   color: '#81D4FA',
    // };

    this.memoryMap[newP?.id?.id] = cloneDeep(newP);

    this.products.unshift(newP);
    if (addToSelectedItems) {
      if (!this.selectedProducts3) {
        this.selectedProducts3 = [];
      }
      if (this.products.length - 1 >= 0) this.selectedProducts3.push(this.products[0]);
      this.selectedProducts3 = [...this.selectedProducts3];
    }
  }

  onSelectItem(event) {
    // this.tableQuestions.push(event);
    // this.searchQ = "";
  }

  onRowSelect(event) {
    let selectedItem = event?.data;
    let selectedItemId = selectedItem?.id.id;

    let targetItem = this.selectedProducts3.find((el) => el.id.id == selectedItemId);

    if (targetItem) {
      this.memoryMap[targetItem.id.id] = cloneDeep(targetItem);
    }
  }

  onRowEditInit(product: any) {
    this.clonedProducts[product.id] = cloneDeep(product);
  }
  onRowDelete(product: any, event: any) {
    this.appDialogService.confirmPopup(
      {
        accept: () => {
          let selectedItemId = product?.id.id;
          let targetIndex = this.products.findIndex((el) => el.id.id == selectedItemId);

          let targetSelectionIndex = this.selectedProducts3.findIndex((el) => el.id.id == selectedItemId);
          if (targetSelectionIndex != -1) {
            (this.selectedProducts3 as any[]).splice(targetSelectionIndex, 1);
          }
          if (targetIndex != -1) {
            let targetItem = this.products[targetIndex];
            delete this.memoryMap[targetItem.id.id];
            (this.products as any[]).splice(targetIndex, 1);
          }
        },
      },
      event.target
    );
  }

  onRowEditSave(product: any) {
    let selectedItemId = product?.id.id;
    let targetItem = this.products.find((el) => el.id.id == selectedItemId);

    this.memoryMap[selectedItemId] = cloneDeep(targetItem);

    delete this.clonedProducts[product.id];
  }

  onRowEditCancel(product: any, index: number) {
    this.products[index] = this.clonedProducts[product.id];
    delete this.clonedProducts[product.id];
  }

  selectedProduct(product) {
    let selectedItemId = product?.id;

    if (this.selectedProducts3) {
      let targetItem = this.selectedProducts3.find((el) => el.id == selectedItemId);
      if (targetItem) return true;
    }

    return false;
  }

  clearTableAndSelection() {
    this.products = [];
    this.selectedProducts3 = [];
    this.memoryMap = {};
  }

  confirmAddLink() {
    this.ref.close(this.getData());
  }
}
