import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';

@Component({
  selector: 'app-badge-view',
  templateUrl: './badge-view.component.html',
  styleUrls: ['./badge-view.component.scss'],
})
export class BadgeViewComponent extends BaseViewItem implements OnInit {
  constructor() {
    super();
  }
  @Input() smallMode: boolean = false;

  ngOnInit(): void {}
  onSetData(): void {}
  get code() {
    return this.data?.name || this.data?.code || this.data?.name || this.data;
  }
  get translateableData() {
    return this.translationKeyPrefix ? `${this.translationKeyPrefix}.enums.${this.data}` : this.data;
  }
  clearData() {}
}
