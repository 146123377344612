import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, RequestWithProgressModel } from '@shared/classes';

@Component({
  selector: 'app-attachment-item-list-view',
  templateUrl: './attachment-item-list-view.component.html',
  styleUrls: ['./attachment-item-list-view.component.scss'],
})
export class AttachmentItemListViewComponent
  extends BaseViewItem<{ [x: string]: RequestWithProgressModel }>
  implements OnInit
{
  @Input() uploadRequestList: { [x: string]: RequestWithProgressModel } = {};
  @Input() externalCode: string | string;

  constructor() {
    super();
  }
  ngOnInit(): void {}
  onCancelUpload(fileUUID: string) {
    this.uploadRequestList[fileUUID].requestSubscriber.unsubscribe();
    this.uploadRequestList[fileUUID].progress = 0;
    this.uploadRequestList[fileUUID].inProgress = false;
    delete this.uploadRequestList[fileUUID];
    // this.onUploadRequestListChange()
  }
}
