import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DynamicComponentBase,
  RiskMethodologyImpactFactor,
  SettingAccessRecord,
  getEnumOptions,
  getOptionsFromStringArray,
  isNullObj,
} from '@shared/classes';
import { ImpactValue } from '@shared/classes/model/backend/entity/model/impactValue';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-default-access-control-selector',
  templateUrl: './default-access-control-selector.component.html',
  styleUrls: ['./default-access-control-selector.component.scss'],
})
export class DefaultAccessControlSelectorComponent extends DynamicComponentBase implements OnInit {
  accessTypeList = getEnumOptions(SettingAccessRecord.AccessTypeEnum);
  discTypeList = getEnumOptions(SettingAccessRecord.DiscretionaryAccessModeEnum);
  accessLevelList = getOptionsFromStringArray([
    'OWNER',
    'ADMIN',
    'UPDATE',
    'READ',
  ] as SettingAccessRecord.AccessLevelEnum[]);
  inheritedAccessModeList = getEnumOptions(SettingAccessRecord.InheritedAccessModeEnum);
  finalVal: SettingAccessRecord;
  fieldList = [
    { label: 'Owner', value: 'owner' },
    { label: 'Attestation Respondent', value: 'attestationRespondent' },
  ];
  fieldList2 = [
    { label: 'Control Objective', value: 'controlObjective' },
    { label: 'Control Entity', value: 'controlEntity' },
    { label: 'Policy', value: 'policy' },
    { label: 'Policy Section', value: 'policySection' },
    { label: 'Control Attestation Template', value: 'controlAttestationTemplate' },
  ];
  // principle
  // principleType
  // accessLevel
  // accessType
  // discretionaryAccessMode
  // applyOnUpdate
  // directAccessPrinciples
  // entityAccessFields
  // inheritedAccessFields
  accessForm: FormGroup = new FormGroup({
    // principle : new FormControl(null),
    // principleType : new FormControl(null),
    accessLevel: new FormControl(null),
    accessType: new FormControl(null),
    discretionaryAccessMode: new FormControl(null), //depending direct
    // applyOnUpdate : new FormControl(false),
    inheritedAccessMode: new FormControl(SettingAccessRecord.InheritedAccessModeEnum.Direct),
    directAccessPrinciples: new FormControl(null), //depending direct
    entityAccessFields: new FormControl(null), //depending field and calculated based on mapper servicer
    inheritedAccessFields: new FormControl(null), //depending inherited and calculated based on mapper servicer
  });
  originalFactors: RiskMethodologyImpactFactor[] = [];
  private _items: RiskMethodologyImpactFactor[] = [];
  @Input() set items(impactFactors: RiskMethodologyImpactFactor[]) {
    // this.originalFactors = impactFactors;
    // this._items = impactFactors.map(item => {
    //     return {
    //         label: item.name,
    //         items: Object.keys(item.options).map(key => {
    //             const option: any = item.options[key];
    //             return {
    //                 label: key,
    //                 value: option
    //             }
    //         }),
    //         code: item.code,
    //     }
    // });
    // this.filteredItems = [...this._items];
  }
  get impactFactors() {
    return this._items;
  }
  valueItems = [];
  impactFactorValues: ImpactValue[] = [];

  uiChange = false;
  filteredItems: any[] = [];
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setupSubscriptions();
  }
  setupSubscriptions() {
    this.accessForm.patchValue(this.fControl?.getRawValue() || null, { emitEvent: false });
    // this.valueItems = this.fControl?.getRawValue()?.factor ? this.formatGetItems(this.impactFactors,this.fControl?.getRawValue()?.factor) : [];
    this.fControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      // this.impactFactors.find()
      this.accessForm.patchValue(
        res === null
          ? {
            accessLevel: null,
            accessType: null,
            discretionaryAccessMode: null,
            // applyOnUpdate: false,
            inheritedAccessMode: SettingAccessRecord.InheritedAccessModeEnum.Direct,
            directAccessPrinciples: null,
            entityAccessFields: null,
            inheritedAccessFields: null,
          }
          : {
            ...res,
            // directAccessPrinciples: (res.directAccessPrinciples || []).map(x=> {
            //     return{
            //         name:x.principle,
            //         type:x.principleType
            //     }
            // }),
          },
        { emitEvent: false }
      );
      // this.impactFactorControl.patchValue(res?.factor || null,{emitEvent:false});
      // this.impactFactorValueControl.patchValue(res?.value || null,{emitEvent:false});
      // if(!res?.factor){
      //     this.valueItems = [];
      //     this.impactFactorValueControl?.removeValidators(Validators.required);
      //     this.impactFactorValueControl?.updateValueAndValidity({emitEvent:false});
      // }else{
      //     this.impactFactorValueControl?.addValidators(Validators.required);
      //     this.impactFactorValueControl?.updateValueAndValidity({emitEvent:false});
      // }
      if (this.fControl?.touched) {
        this.accessForm?.markAsTouched();
        this.accessForm?.markAsDirty();
        // this.impactFactorValueControl?.markAsTouched();
        // this.impactFactorValueControl?.markAsDirty();
      } else {
        this.accessForm?.markAsUntouched();
        this.accessForm?.markAsPristine();
        // this.impactFactorValueControl?.markAsUntouched();
        // this.impactFactorValueControl?.markAsPristine();
      }
    });
    this.accessForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      // this.valueItems = res ? this.formatGetItems(this.impactFactors,res) : [];
      // this.impactFactorValueControl.patchValue(null);
      // this.impactFactorValueControl.updateValueAndValidity();
      this.fControl.patchValue(
        res
          ? {
            ...res,
            // directAccessPrinciples: (res.directAccessPrinciples || []).map(x=> {
            //     return{
            //         principle: x.name,
            //         principleType: x.type
            //     }
            // }),
          }
          : null
      );
      // if(isNullObj(this.valueItems)){
      //     this.impactFactorValueControl?.removeValidators(Validators.required);
      //     this.impactFactorValueControl?.updateValueAndValidity();
      // }else{
      //     this.impactFactorValueControl?.addValidators(Validators.required);
      //     this.impactFactorValueControl?.updateValueAndValidity();
      //     this.fControl.setErrors({impactFactorValue:true})
      // }
    });
    // this.impactFactorValueControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(res=>{
    //     this.fControl.patchValue(this.impactFactorControl.getRawValue() ? {
    //         factor: this.impactFactorControl.getRawValue(),
    //         value: res,
    //     } : null);
    //     if(isNullObj(res)) this.fControl.setErrors({impactFactorValue:true})
    // })
  }
  onSetLiveData(): void {
    const selectedFactorList = this.liveDynamicData as { factorVal: { factor: any; value: any } }[];
    // if(!isNullObj(selectedFactorList)){
    //     let filtered : any[] = [];
    //     for(let i = 0; i < this.impactFactors.length; i++) {
    //         let item = this.impactFactors[i];
    //             if(selectedFactorList.find(x=> x?.factorVal?.factor?.code == item?.code))
    //             {

    //             }
    //             else{
    //                 filtered.push(item);
    //             }
    //     }
    //     this.filteredItems = filtered;
    // }else{
    //     this.filteredItems = [...this.impactFactors];
    // }
  }
  setInputOptions(): void {
    this.items = this.inputOptions?.dropDownInput?.items ?? this.items;
    this.setupSubscriptions();
  }
  formatGetItems(items: any[], linkedControlValue: any) {
    if (isNullObj(items) || isNullObj(linkedControlValue)) return [];
    let value = items.find((item) => item.code === linkedControlValue?.code);

    if (value) {
      return value.items.map((item) => ({
        label: item?.label,
        value: item?.value,
        code: item?.code || item?.value?.code,
      }));
    } else {
      return [];
    }
  }
}
