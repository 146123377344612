<!-- <p-toolbar styleClass="mb-8">
<div class="p-toolbar-group-left">
</div>

<div class="p-toolbar-group-right">
  <p-button icon="pi pi-filter" (click)="onCreateReq()" label="Apply Filter" styleClass="p-button"></p-button>
</div>
</p-toolbar> -->

<p-pickList
  #pickList
  [source]="sourceElements"
  [target]="targetElements"
  sourceHeader="Available Entities"
  targetHeader="Selected Entities"
  [dragdrop]="true"
  [responsive]="true"
  [sourceStyle]="{ height: '30rem' }"
  [targetStyle]="{ height: '30rem' }"
  filterBy="name"
  sourceFilterPlaceholder="Search by Entity name"
  targetFilterPlaceholder="Search by Entity name"
  (onMoveAllToSource)="onChange()"
  (onMoveAllToTarget)="onChange()"
  (onMoveToSource)="onChange()"
  (onMoveToTarget)="onChange()"
  class="w-full"
  [disabled]="viewMode == 'view'"
>
  <ng-template pTemplate="sourceFilter" let-options="options">
    <div class="flex flex-row w-full">
      <div class="flex flex-1">
        <div class="p-picklist-filter w-full">
          <input
            appMaxCharacterValidator
            [(ngModel)]="sourceFilterValue"
            type="text"
            (keyup)="options.filter($any($event.target).value)"
            role="textbox"
            class="p-picklist-filter-input p-inputtext p-component border-noround border-round-left"
            placeholder="Search by Entity name"
          />
          @if (sourceFilterValue) {
            <span class="p-picklist-filter-icon pi pi-times" (click)="options.reset(); sourceFilterValue = ''"></span>
          } @else {
            <span [class]="'p-picklist-filter-icon ' + (loading ? 'pi pi-spin pi-spinner' : 'pi pi-search')"></span>
          }
        </div>
      </div>
      <!-- <div class="flex">
        <p-button icon="pi pi-filter" (click)="onCreateReq()" label=""
        [styleClass]="'p-button border-noround ' + (showCategoriesSelector ? '' : ' border-round-right')"></p-button>
      </div> -->
      @if (showCategoriesSelector) {
        <div class="flex">
          <app-button
            pTooltip="Categories"
            tooltipPosition="top"
            (onClickAction)="op.toggle($event)"
            [action]="{
              id: 2,
              label: '',
              icon: 'pi pi-table',
              iconPos: 'left',
              buttonStyle: 'raised',
              color: 'primary',
              buttonClass: 'border-noround border-round-right',
            }"
          ></app-button>
        </div>
      }
    </div>
  </ng-template>
  <ng-template let-product pTemplate="item">
    <div class="product-item">
      <div class="product-list-detail align-items-center flex gap-2">
        @if (product?.code) {
          <app-badge-item
            [styleClass]="'text-xs'"
            [status]="product?.recordStatus || 'gray'"
            [text]="product.code"
          ></app-badge-item>
        }
        <span class="product-category">{{ product?.name }} </span>
      </div>
    </div>
  </ng-template>
</p-pickList>
<p-overlayPanel #op [showCloseIcon]="true">
  <ng-template pTemplate>
    <p-listbox
      [options]="listOfCategories"
      [checkbox]="true"
      [filter]="true"
      [multiple]="true"
      [formControl]="categoriesControl"
      optionLabel="label"
      [optionValue]="'value'"
      [style]="{ width: '15rem' }"
    >
      <ng-template let-item pTemplate="item">
        <app-badge-item [status]="item.value" [text]="item.label"></app-badge-item>
      </ng-template>
    </p-listbox>
  </ng-template>
</p-overlayPanel>
