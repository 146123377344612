import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseForm, IAction, getModuleKeywordByCode, humanizeCasedString } from '@shared/classes';
import { PageableOptions } from '@shared/classes/model/frontend/pageable-options';
import { AppDialogService, ViewModeService } from '@shared/services';
import { TagsSearchDataService } from '@shared/services/hub-services/tags-search-data.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'app-global-search-popup',
  templateUrl: './global-search-popup.component.html',
  styleUrl: './global-search-popup.component.scss',
})
export class GlobalSearchPopupComponent extends BaseForm<any> implements OnInit {
  goToMorePageAction: IAction = {
    id: 1,
    label: 'View More',
    buttonType: 'button',
    command: this.goToMorePage.bind(this),
    icon: 'pi pi-angle-down',
    color: 'secondary',
    buttonStyle: 'text',
    buttonClass: 'w-full',
  };

  tagsStringControl = new FormControl(null);
  tabItems = [];
  searchItems = [];
  filteredItems = [];
  allItems = [];
  _currentItem;
  set currentItem(value) {
    this._currentItem = value;
  }
  get currentItem() {
    return this._currentItem;
  }
  loading = false;
  filterLoading = false;
  searchable = false;
  currentQuery;
  currentTags;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public requestService: TagsSearchDataService,
    private appDialogService: AppDialogService,
    public viewModeService: ViewModeService,
    public router: Router
  ) {
    super(viewModeService, null);
    if (!this.data) this.data = {};
    this.formData = this.data;
  }
  ngOnInit(): void {}
  getData() {
    return this.data;
  }
  setData(data: any) {}
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      query: new FormControl(null),
      tags: new FormControl(null),
    });
    this.tagsStringControl = new FormControl(null);

    this.formGroup.controls.query.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((x) => {
        if (this.currentItem?.id == 'all' || !this.currentItem?.id) {
          this.search(x, this.tagsStringControl?.value?.length ? this.tagsStringControl?.value : null);
        } else if (this.currentItem?.id) {
          this.searchFiltered(
            x,
            this.tagsStringControl?.value?.length ? this.tagsStringControl?.value : null,
            this.currentItem?.id
          );
        }
      });

    this.formGroup.controls.tags.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((x) => {
        if (this.currentItem?.id == 'all' || !this.currentItem?.id) {
          this.search(
            this.formGroup.controls?.query?.value ?? null,
            this.tagsStringControl?.value?.length ? this.tagsStringControl?.value : null
          );
        } else if (this.currentItem?.id) {
          this.searchFiltered(
            this.formGroup.controls?.query?.value ?? null,
            this.tagsStringControl?.value?.length ? this.tagsStringControl?.value : null,
            this.currentItem?.id
          );
        }
      });
  }

  search(keyword, tags) {
    if (keyword || tags?.length) {
      this.searchable = true;
      this.currentQuery = keyword;
      this.currentTags = tags;
      this.loading = true;
      this.requestService.globalSearch(keyword, tags, [], new PageableOptions()).subscribe((res) => {
        if (res?.content) {
          this.loading = false;
          this.searchItems = res.content;
          this.allItems = [...this.searchItems];
          const tabObj = {};
          this.tabItems = [];
          this.searchItems.forEach((x) => {
            if (x?.code) {
              try {
                const module = getModuleKeywordByCode(x?.code);
                tabObj[module] = module;
              } catch (e) {
                console.error("Couldn't Get Module Keyword from " + x?.code);
              }
            }
          });
          if (this.searchItems?.length) {
            this.currentItem = { label: 'All', id: 'all' };
            this.tabItems.push({ ...this.currentItem });
          }
          Object.values(tabObj).forEach((x) => {
            this.tabItems.push({ label: humanizeCasedString(x as any), id: x, command: () => {} });
          });
        }
      });
    } else {
      this.searchable = false;
    }
  }

  searchFiltered(keyword, tags, id) {
    if (keyword || tags?.length) {
      this.searchable = true;
      this.filterLoading = true;
      this.currentQuery = keyword;
      this.currentTags = tags;
      this.requestService.globalSearch(keyword, tags, id ? [id] : [], new PageableOptions()).subscribe((res) => {
        this.filterLoading = false;
        if (res?.content) {
          this.filteredItems = res?.content;
        }
      });
    } else {
      this.searchable = false;
    }
  }

  goToMorePage() {
    this.ref.close(null);
    this.router.navigate(['/global-search'], {
      queryParams: {
        q: this.formGroup.controls.query.value ?? '', // 'q' is the search query parameter
        tags: this.tagsStringControl?.value?.join(','), // Convert the array to a comma-separated string
      },
    });
  }

  onActiveItemChange(event) {
    if (event.id != this.currentItem?.id) {
      this.currentItem = event;
      if (this.currentItem?.id && this.currentItem?.id != 'all') {
        this.searchFiltered(this.currentQuery, this.currentTags, this.currentItem?.id);
      }
    }
  }
}
