import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import { PermissionParserService } from '@core/services/permission-parser/permission-parser.service';
import {
  ModuleKeywordRootPath,
  ModuleKeywords,
  PermissionActions,
  makePlural,
  routeToLocaleCase,
  toCamelCase,
} from '@shared/classes';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-module-permissions-card-view',
  templateUrl: './module-permissions-card-view.component.html',
  styleUrls: ['./module-permissions-card-view.component.scss'],
})
export class ModulePermissionsCardViewComponent implements OnInit {
  @Input() title: string = '';
  @Input() description: string = '';

  @Input() createPermission: string | string[];
  @Input() readPermission: string | string[];
  @Input() updatePermission: string | string[];
  @Input() deletePermission: string | string[];

  permissionList: { viewPermission: string; permissions: string[] }[] = [];

  private _moduleKeyword: string;
  @Input() set moduleKeyword(moduleKeyword: string) {
    this._moduleKeyword = moduleKeyword;
    this.onSetModuleKeyword();
  }
  get moduleKeyword() {
    return this._moduleKeyword;
  }
  constructor(
    private router: Router,
    private permissionService: NgxPermissionsService,
    private permissionParserService: PermissionParserService,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {}
  onSetModuleKeyword() {
    if (this.moduleKeyword) {
      this.createPermission = PermissionActions.Create + this.moduleKeyword;
      this.readPermission = PermissionActions.Read + this.moduleKeyword;
      this.updatePermission = PermissionActions.Update + this.moduleKeyword;
      this.deletePermission = PermissionActions.Delete + this.moduleKeyword;
      const translatedTitle = this.localeService.translate(
        `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
      );
      this.title = `${translatedTitle} ${this.localeService.translate('general.messages.permissions')}`;

      this.permissionList = [];
      Object.keys(PermissionActions).forEach((key) => {
        const viewPerm =
          this.localeService.translate(`general.cards.permission.${PermissionActions[key]}`) + ' ' + translatedTitle;
        this.permissionList.push({
          viewPermission: viewPerm,
          permissions: this.permissionParserService.permissionsMap[viewPerm] || [],
        });
      });
    }
  }
  parseModuleKeyword(keyword: ModuleKeywords): string {
    if (keyword == 'QUESTIONNAIRE_VALUE') return 'QuestionnaireAnswers';
    if (keyword == 'MY_QUESTIONNAIRE_VALUE') return 'MyQuestionnaireAnswers';
    return keyword;
  }
}
