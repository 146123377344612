<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="col-12 md:col-6">
    <app-check-box
      [label]="moduleFieldString + '.sendEmails.label' | translate: 'Send Emails'"
      [name]="'send-emails'"
      [control]="formGroup?.controls?.sendEmails"
      [viewMode]="fieldViewMode"
    >
    </app-check-box>
  </div>
  <div class="col-12 md:col-6">
    <app-target-code-selector
      [label]="moduleFieldString + '.lang.label' | translate: 'Lang'"
      [placeholder]="moduleFieldString + '.lang.placeholder' | translate: 'Enter Lang'"
      [control]="formGroup?.controls?.lang"
      [viewMode]="fieldViewMode"
      [multi]="false"
      [optionLabel]="'name'"
      [optionValue]="'code'"
      [targetTypes]="['LANGUAGE']"
      [customProjectionFields]="['code', 'name', 'recordStatus', 'langCode']"
    >
    </app-target-code-selector>
  </div>
  <!-- <div class="col-12 md:col-6">
    <app-basic-input
      label="Language"
      [placeholder]="'Enter Language'"
      [control]="formGroup?.controls?.lang"
      [viewMode]="fieldViewMode"
    ></app-basic-input>
  </div> -->
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
