import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentViewDto } from '@shared/classes';
import { RiskItem, RiskMethodologyImpactValue, RiskMethodologyLikelihoodValue } from '@shared/classes/model/backend/risk/model/models';
import { RiskMethodology } from '@shared/classes/model/backend/risk/model/riskMethodology';
import { ThresholdValue } from '@shared/classes/model/backend/risk/model/thresholdValue';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';
import { RiskItemDataService } from 'app/modules/risk/services/data/risk-item-data.service';
import { RiskMethodologyDataService } from 'app/modules/risk/services/data/risk-methodology-data.service';
import { cloneDeep, find, maxBy, minBy } from 'lodash-es';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-dashlet-risk-methodology',
  templateUrl: './dashlet-risk-methodology.component.html',
  styleUrls: ['./dashlet-risk-methodology.component.scss'],
})
export class DashletRiskMethodologyComponent extends BaseViewItem implements OnInit {
  _riskItems: RiskItem[] = [];
  tableCardContent: ContentViewDto;
  drilldownTableVisible = false;
  home: MenuItem = { icon: 'pi pi-home' };
  drillDownPreviousOptions: MenuItem[] = [];
  get riskItems() {
    return this._riskItems;
  }

  @Input() set riskItems(data: RiskItem[]) {
    this._riskItems = data;
    this.calculateRiskAmounts();
  }
  @Output() onInit: EventEmitter<any> = new EventEmitter();

  @Input() title: string;

  activeMethodology: RiskMethodology;
  public activeMeth: RiskMethodology;
  public impactList: RiskMethodologyImpactValue[];

  public likelihoodList: RiskMethodologyLikelihoodValue[];
  public thresholdList: ThresholdValue[];

  public minThreshold;
  public maxThreshold;
  stateExist: boolean;
  newRM = false;

  public riskMap: Map<string, number> = new Map<string, number>();
  public impactValues = {};
  public likelihoodValues = {};
  public totalCnt = 0;

  acceptableRiskValue = 50;

  options: any = {
    floor: 0,
    ceil: 100,
  };

  selectedRm: RiskMethodology;
  selectedRmName: string;
  selectedRmDescription: string;
  constructor(
    private riskMethodologyDataService: RiskMethodologyDataService,
    private riskItemDataService: RiskItemDataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.riskMap = new Map<string, number>();
    this.impactValues = {};
    this.likelihoodValues = {};
    this.totalCnt = 0;

    this.riskMethodologyDataService.fetchActiveRiskMethodology().subscribe({
      next: (res) => {
        this.activeMethodology = res.data;
        this.refreshSelected(this.activeMethodology);

        this.stateExist = true;
        this.newRM = !this.activeMethodology ? true : false;
        !this.newRM ? this.refreshSelected(this.activeMethodology) : '';
        this.selectedRmName = this.activeMeth?.name;
        this.selectedRmDescription = this.activeMeth?.description;

        if (this.onInit) {
          this.onInit.emit();
        }
      },
      error: (error) => { },
      complete: () => { },
    });
  }

  calculateRiskAmounts() {
    if (this.riskItems && this.riskItems.length > 0) {
      this.riskItems.forEach((item) => {
        const residualImpact = this.activeMethodology.impactValuesDto.find(x => x.code == item?.residualImpact);

        if (item?.residualLikelihood && item?.residualImpact && residualImpact) {
          const cnt = this.riskMap.has(residualImpact.name + item.residualLikelihoodDto.name)
            ? this.riskMap.get(residualImpact.name + item.residualLikelihoodDto.name)
            : 0;
          const newCnt = cnt + 1;
          this.riskMap.set(residualImpact.name + item.residualLikelihoodDto.name, newCnt);
          this.impactValues[residualImpact.name] = (this.impactValues[residualImpact.name] ?? 0) + newCnt;
          this.likelihoodValues[item?.residualLikelihoodDto.name] =
            (this.likelihoodValues[item?.residualLikelihoodDto.name] ?? 0) + newCnt;
          this.totalCnt++;
        }
      });
    }
  }

  refreshSelected(rm: RiskMethodology) {
    this.activeMeth = cloneDeep(rm);
    this.impactList = this.activeMeth.impactValuesDto;
    // this.prepareFactors();
    this.likelihoodList = this.activeMeth.likelihoodValuesDto;
    this.thresholdList = this.activeMeth.thresholdValuesDto;
    this.stateExist = true;
    this.calculateMinMax();
  }

  calculateMinMax() {
    this.maxThreshold = maxBy(this.impactList, 'value').value * maxBy(this.likelihoodList, 'value').value;
    this.minThreshold = minBy(this.impactList, 'value').value * minBy(this.likelihoodList, 'value').value;

    this.acceptableRiskValue =
      this.activeMeth?.acceptableRisk > 0
        ? this.activeMeth?.acceptableRisk
        : (this.maxThreshold + this.minThreshold) / 2;
    this.options = {
      floor: this.minThreshold,
      ceil: this.maxThreshold,
    };
  }

  getCellColor(cellValue) {
    let threshold = find(this.activeMeth?.thresholdValuesDto, function (o) {
      return o.value >= cellValue;
    });

    if (threshold != undefined) return threshold.color;
    else return 'transparent';
  }
  onDrillDownItemClick(event) {
    const item = event.item;
    if (item.icon) {
      this.drilldownTableVisible = false;
    } else {
    }
  }
  showDrilldownTable(options) {
    this.drillDownPreviousOptions = [{ label: options.residualLikelihood + ' & ' + options.residualImpact + ' Risks' }];
    this.drilldownTableVisible = true;
    this.tableCardContent = {
      // ...this.cardContent?.contentViewObject,
      targetType: 'RISK_ITEM',
      tableViewConfig: {
        // ...this.cardContent?.contentViewObject?.drillDownTableViewConfig,
        showActions: false,
        showOperationActions: false,
        showGlobalFilter: false,
        showSettingsLink: false,
        showFileExportAction: false,
        showPackageExportAction: false,
        showBulkOperationAction: false,
        showDeleteAction: false,
        showEditAction: false,
        showLockAction: false,
        showActivateAction: false,
        showDeactivateAction: false,
        showStatusActions: false,
        showAddAction: false,
        showUnLockAction: false,
        showReviseRelationsAction: false,
      },
      filterView: {
        // ...this.cardContent?.contentViewObject?.filterView,
        filter: {
          // ...this.cardContent?.contentViewObject?.filterView.filter,
          // projectionFields: this.riskItemDataService.projectionFields,
          filters: [
            // ...(this.cardContent?.contentViewObject?.filterView?.filter?.filters
            //   ? this.cardContent?.contentViewObject?.filterView?.filter?.filters
            //   : []),
            { property: 'status', operation: 'EQUAL', value: 'APPROVED' },
            {
              property: 'residualLikelihood.name',
              operation: 'EQUAL',
              value: options.residualLikelihood,
              typeShape: 'NORMAL',
            },
            { property: 'residualImpact.name', operation: 'EQUAL', value: options.residualImpact, typeShape: 'NORMAL' },
          ],
        },
      },
    };
  }
}
