import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemBasePage, MessageKeys, ModuleKeywords } from '@shared/classes';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { UserPreferenceDataService } from 'app/modules/users/services/data/user-preference-data.service';

@Component({
  selector: 'app-user-preference-item',
  templateUrl: './user-preference-item.component.html',
  styleUrl: './user-preference-item.component.scss',
})
export class UserPreferenceItemComponent extends ItemBasePage<any> implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private requestService: UserPreferenceDataService,
    private toastService: ToastService,
    public viewModeService: ViewModeService,
    private router: Router
  ) {
    super(
      {
        moduleKeyword: ModuleKeywords.User,
        routePrefix: 'user-preferences',
      },
      router,
      requestService,
      toastService
    );

    this.subs.sink = this.route.params.subscribe({
      next: (params) => {
        this.itemId = params['id'];
        this.SetPageValues({
          breadCrumb: {
            items: [
              { label: 'User Preferences' },
              {
                label: this.itemId ? this.itemId : 'Profile',
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: null,
        });
      },
    });
  }

  ngOnInit(): void {}

  getItemData(id: string) {
    this.subs.sink = this.requestService.myPreferences().subscribe({
      next: (res: any) => {
        if (res?.data) this.data = { ...res.data };
      },
    });
  }

  updateItemV3(data: any, redirectState: 'BACK' | 'PAGE' | 'NONE' = 'BACK') {
    this.subs.sink = this.requestService.updatePreferences({ updateItems: data }).subscribe({
      next: (res) => {
        this.toastService.success(MessageKeys.success, 'User Preferences Updated Successfully');
        this.handleRedirectState(null, redirectState);
        window?.location?.reload();
      },
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
