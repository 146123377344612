import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, getEnumOptions } from '@shared/classes';
import { BulkOperation } from '@shared/classes/view/BulkOperation';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-bulk-operation-choice-step-form',
  templateUrl: './bulk-operation-choice-step-form.component.html',
  styleUrls: ['./bulk-operation-choice-step-form.component.scss'],
})
export class BulkOperationChoiceStepFormComponent extends BaseForm<any> implements OnInit {
  operations = getEnumOptions(BulkOperation);

  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, null);
  }

  ngOnInit(): void {}

  getData() {
    return {
      ...this.formGroup.getRawValue(),
    };
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      operation: new FormControl(null, Validators.required),
    });
  }
}
